<h2 mat-dialog-title>Società {{currentItem.name}}</h2>
<mat-dialog-content>
    <div class="content">
        <mat-form-field class="field-full-width">
            <mat-label>Nome società</mat-label>
            <input matInput placeholder="Nome società" [(ngModel)]="currentItem.name" />
        </mat-form-field>
        <mat-form-field class="field-full-width">
            <mat-label>Descrizione</mat-label>
            <input matInput placeholder="Descrizione" [(ngModel)]="currentItem.description" />
        </mat-form-field>
        <mat-form-field class="field-full-width">
            <mat-label>Indirizzo</mat-label>
            <input matInput placeholder="Indirizzo" [(ngModel)]="currentItem.address" />
        </mat-form-field>
        <mat-form-field>
            <mat-label>Contatto</mat-label>
            <input matInput placeholder="contatto" [(ngModel)]="currentItem.manager" />
        </mat-form-field>
        <mat-form-field>
            <mat-label>eMail</mat-label>
            <input matInput placeholder="eMail" [(ngModel)]="currentItem.email" />
        </mat-form-field>
        <mat-form-field>
            <mat-label>Telefono</mat-label>
            <input matInput placeholder="Telefono" [(ngModel)]="currentItem.phone" />
        </mat-form-field>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-raised-button (click)="cancel()">Chiudi</button>
    <button mat-raised-button (click)="saveDetail()" color="warn">Aggiorna</button>
</mat-dialog-actions>