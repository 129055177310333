@if (currentBuilding) {
<h1 mat-dialog-title>Importa dati Appartamenti in stabile: <b>{{currentBuilding.name}}</b></h1>
} @else {
<h1 mat-dialog-title>Importa dati Stabili e Appartamenti</h1>
}
<mat-dialog-content>
    <app-progressspinner *ngIf="progress"></app-progressspinner>
    <mat-form-field class="field">
        <mat-label>Gestore</mat-label>
        <mat-select [(ngModel)]="company" [compareWith]="dataservice.compareByCompanyId">
            <mat-option *ngFor="let company of dataservice.companies" [value]="company">{{ company.name
                }}</mat-option>
        </mat-select>
    </mat-form-field>
    <input type="file" class="file-input" (change)="onAPFileSelected($event)" #fileApartmentUpload />

    <div class="file-upload">
        {{ fileApartmentName || 'Seleziona un file da caricare' }}

        <button mat-mini-fab color="primary" class="upload-btn" (click)="fileApartmentUpload.click()">
            <mat-icon>attach_file</mat-icon>
        </button>
    </div>

    @if (summaryBuildingImport) {

    <div class="response">
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Stabili: {{summaryBuildingImport.summary.length}}

                </mat-panel-title>



                <mat-panel-description>
                    @if (summaryBuildingImport.summary.length>0) {
                    @if (buildingsOk) {
                    <mat-icon class="coloring__green">check_circle</mat-icon>
                    } @else {
                    <mat-icon class="coloring__orange">warning</mat-icon>
                    @if (this.summaryBuildingImport.invalidBuilding) {
                    {{this.summaryBuildingImport.invalidBuilding}} nomi condominio non sono validi
                    }
                    @else {

                    Ci sono stabili da importare già presenti nel database
                    }
                    }
                    }@else {
                    <mat-icon class="coloring__orange">warning</mat-icon>
                    Ci sono stabili da importare già presenti nel database
                    }

                </mat-panel-description>

            </mat-expansion-panel-header>
            @for (item of summaryBuildingImport.summary; track item) {
            <div>{{item.name}}

                @if (!item.isNew) {
                <mat-icon class="coloring__red">dangerous</mat-icon>
                ERRORE: Non ci sono stabili da importare
                } @else {
                <mat-icon class="coloring__green">check_circle</mat-icon>

                }
            </div>
            }
        </mat-expansion-panel>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Appartamenti: {{apartments.length}}

                </mat-panel-title><mat-panel-description>
                    <mat-icon class="coloring__green">check_circle</mat-icon>
                </mat-panel-description>
            </mat-expansion-panel-header>
            @for (item of apartments; track item) {
            <div>{{item}}</div>
            }
        </mat-expansion-panel>

        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Contabilizzatori non validi: {{invalidMeters.length}}

                </mat-panel-title>

                <mat-panel-description>
                    @if (invalidMeters.length>0) {
                    <mat-icon class="coloring__orange">warning</mat-icon> ATTENZIONE: Sono presenti dei contabilizzatori
                    con dati
                    non validi (es seriale errato)
                    } @else {
                    <mat-icon class="coloring__green">check_circle</mat-icon>

                    }

                </mat-panel-description>

            </mat-expansion-panel-header>
            @for (item of invalidMeters; track item) {
            <div>{{item}}</div>
            }
        </mat-expansion-panel>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Contabilizzatori già assegnati: {{existMeters.length}}

                </mat-panel-title>
                <mat-panel-description>
                    @if (existMeters.length>0) {
                    <mat-icon class="coloring__red">dangerous</mat-icon>
                    ERRORE: Alcuni contabilizzatori sono già assegnati ad un altro stabile
                    } @else {
                    <mat-icon class="coloring__green">check_circle</mat-icon>

                    }
                </mat-panel-description>

            </mat-expansion-panel-header>
            @for (item of existMeters; track item) {
            <div>{{item.apartment}}</div>
            }
        </mat-expansion-panel>



    </div>

    <button mat-raised-button [disabled]="getDisabled()" (click)="startImport()" color="warn">Avvia import</button>
    @if (getDisabled()) {
    <span class="imperror">Correggi gli errori prima di procedere all'import</span>
    }

    }
    <!--
    <div class="importSummary" *ngIf="summaryBuildingImport!==undefined">
        @for (item of summaryBuildingImport.summary; track item) {
        <div class="importSummary__item">

            <h2>{{item.name}}</h2>
            <table class="importTable">
                <tr>
                    <th>#</th>
                    <th>Seriale</th>
                    <th>Appartamento</th>
                    <th>Nome</th>
                    <th>Nuovo</th>
                    <th>Radiatore</th>


                </tr>
                <tr *ngFor="let device of item.devices; index as i">
                    <td>{{i+1}}</td>
                    <td>{{device.serial}}</td>
                    <td>{{device.apartment}}</td>
                    <td>{{device.location}}</td>
                    <td>
                        {{device.isNew?"Si":"No"}}
                    </td>

                    <td>

                        <div class="importTable__ok" [ngClass]="{'importTable__err':!device.haveParameters}">
                            <mat-icon class="importTable__ok"
                                *ngIf="device.haveParameters">check-haveParameters</mat-icon>
                            <mat-icon class="importTable__err" *ngIf="!device.haveParameters">error</mat-icon>
                            {{device.haveParameters?"Si":"No"}} UNI: {{device.radiatorType}} Tipo:
                            {{device.radiatorCode}}
                        </div>
                    </td>
                </tr>
            </table>
        </div>
        }

        <table class="errorTable">
            <tr>
                <th>Errori</th>
            </tr>
            <tr *ngFor="let item of summaryBuildingImport.errors; index as i">
                <td>{{item}}</td>
            </tr>
            <tr *ngIf="summaryBuildingImport.errors.length==0">
                <td>
                    <div class="importTable__ok">
                        Nessun errore
                    </div>
                </td>
            </tr>
        </table>
    </div>-->
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-raised-button (click)="cancel()">Chiudi</button>


</mat-dialog-actions>