import { IBuildingBillingInfo, IBillingBuilding, IBuilding, IBreadcrumbData } from './../../interfaces';
import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource, MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { DataserviceService } from '../dataservice.service';
import { MatIcon } from '@angular/material/icon';
import { MatIconAnchor, MatIconButton, MatAnchor } from '@angular/material/button';
import { NgIf, DatePipe } from '@angular/common';
import { BreadcrumbnavComponent } from '../breadcrumbnav/breadcrumbnav.component';
import { ProgressspinnerComponent } from "../progressspinner/progressspinner.component";

@Component({
    selector: 'app-billinglist',
    templateUrl: './billinglist.component.html',
    styleUrls: ['./billinglist.component.scss'],
    standalone: true,
    imports: [BreadcrumbnavComponent, MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, NgIf, MatIconAnchor, MatIcon, MatIconButton, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow, MatPaginator, MatAnchor, DatePipe, ProgressspinnerComponent]
})
export class BillinglistComponent {
    @ViewChild(MatPaginator) paginator: MatPaginator;
    dataSource: MatTableDataSource<IBillingBuilding>;
    monts: string[] = [];
    billmonth = 0;
    refValue = 0;
    currentId = 0;
    building: IBuilding;
    breadcrumb: IBreadcrumbData[] = [];
    progress = false;

    constructor(private route: ActivatedRoute, public dialog: MatDialog, public dataservice: DataserviceService) {
        this.progress = true;
        this.route.paramMap.subscribe(async (map) => {
            // console.log('Pmap; ', map.get('id'));
            this.currentId = parseInt(map.get('id'), 10);

            this.dataservice.getBillBuildingList(this.currentId).subscribe(
                (data) => {
                    this.dataSource = new MatTableDataSource<IBillingBuilding>(data as IBillingBuilding[]);
                    this.dataservice.getBuilding(this.currentId).subscribe((building) => {
                        this.progress = false;
                        this.building = building as IBuilding;
                        this.breadcrumb.push({ label: 'HOME', url: '/' });
                        this.breadcrumb.push({ label: 'stabili', url: '/buildings' });
                        this.breadcrumb.push({ label: this.building.name, url: '/building/' + this.building.buildingId });
                        this.breadcrumb.push({ label: 'bollette', url: 'javascript:;' });
                    });
                },
                () => { }
            );
        });
    }

    getBillStartDate(date: number) {
        const month = ['GEN', 'FEB', 'MAR', 'APR', 'MAG', 'GIU', 'LUG', 'AGO', 'SET', 'OTT', 'NOV', 'DIC'];

        return month[date % 12] + ' ' + (Math.trunc(date / 12) + 2020);
    }

    erase(item: IBillingBuilding) {
        this.dataservice.confirm('Vuoi eliminare la bolletta selezionata?', ['Si', 'No']).then((resp) => {
            if (resp === 0) {
                this.dataservice.deleteBilling(item.billingId).subscribe(() => {
                    this.dataservice.getBillBuildingList(this.currentId).subscribe(
                        (data) => {
                            this.dataSource = new MatTableDataSource<IBillingBuilding>(data as IBillingBuilding[]);
                        },
                        () => { }
                    );
                });
            }
        });
    }

    getColumns() {
        return ['position', 'timestamp', 'reference', 'created', 'lastmodify', 'published', 'status', 'actions'];
    }

    canAdd() {
        if (this.dataSource) {
            for (const item of this.dataSource.data) {
                if (item.draft) {
                    return false;
                }
            }
        }
        return true;
    }
}
