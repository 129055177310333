<div class="tabcontainer">
    <div class="sectiontitleicon">
        <img src="/assets/buildings.svg" />
        <div>
            <h2 class="tabtitle">
                Unità abitativa [<b>{{ currentItem?.name }}</b
                >]
                @if (currentItem.isArchived) {
                    <span class="itemarchived">ARCHIVIATA</span>   
                }
            </h2>
            <app-breadcrumbnav [data]="breadcrumb"></app-breadcrumbnav>
        </div>
    </div>

    <div *ngIf="currentItem" class="formcontainer">
        <div>
            <a mat-icon-button href="/myapartment/{{ currentItem.apartmentId }}" matTooltip="Visualizza Report utente"><mat-icon>visibility</mat-icon></a>
            <a mat-icon-button href="/devices/apartment/{{ currentItem.apartmentId }}" matTooltip="Contabilizzatori"><mat-icon [matBadge]="currentItem.metersCount">speaker_phone</mat-icon></a>
            <button mat-icon-button (click)="showuser(currentItem)" matTooltip="Utenti associati"><mat-icon>people_alt</mat-icon></button>
        </div>
        <mat-form-field class="field-full-width">
            <mat-label>Nome</mat-label>

            <input matInput placeholder="Nome" [(ngModel)]="currentItem.name" />
        </mat-form-field>
        <mat-form-field class="field field__floor">
            <mat-label>Numero</mat-label>

            <input matInput placeholder="Numero" [(ngModel)]="currentItem.number" />
        </mat-form-field>
        <mat-form-field class="field field__floor">
            <mat-label>Piano</mat-label>

            <input matInput placeholder="Piano" [(ngModel)]="currentItem.floor" />
        </mat-form-field>
        <mat-form-field class="field field__position">
            <mat-label>Scala</mat-label>

            <input matInput placeholder="Scala" [(ngModel)]="currentItem.staircase" />
        </mat-form-field>
        <mat-form-field class="field field__position">
            <mat-label>Posizione</mat-label>

            <input matInput placeholder="Posizione" [(ngModel)]="currentItem.position" />
        </mat-form-field>
        <mat-form-field class="field-full-width">
            <mat-label>Referente</mat-label>
            <input matInput placeholder="Referente" [(ngModel)]="currentItem.owner" />
        </mat-form-field>
        <mat-form-field class="field field__phone">
            <mat-label>Telefono 1</mat-label>

            <input matInput placeholder="Telefono 1" [(ngModel)]="currentItem.ownerphone" />
        </mat-form-field>
        <mat-form-field class="field field__phone">
            <mat-label>Telefono 2</mat-label>

            <input matInput placeholder="Telefono 2" [(ngModel)]="currentItem.ownerphone2" />
        </mat-form-field>
        <mat-form-field class="field-full-width">
            <mat-label>Email</mat-label>

            <input matInput placeholder="Email" [(ngModel)]="currentItem.owneremail" />
        </mat-form-field>
        <mat-form-field class="field-full-width">
            <mat-label>Note</mat-label>

            <input matInput placeholder="Note" [(ngModel)]="currentItem.note" />
        </mat-form-field>

        <mat-checkbox [(ngModel)]="currentItem.isArchived">Archiviato</mat-checkbox>

        <mat-form-field class="field-full-width">
            <mat-label>Millesimi Riscaldamento</mat-label>

            <input matInput placeholder="Millesimi Riscaldamento" [readonly]="dataservice.currentUser.role < 3" [(ngModel)]="heatMills" />
        </mat-form-field>

        <mat-form-field class="field-full-width">
            <mat-label>Millesimi Energia</mat-label>

            <input matInput placeholder="Millesimi Energia" [readonly]="dataservice.currentUser.role < 3" [(ngModel)]="energyMills" />
        </mat-form-field>

        <mat-form-field class="field-full-width">
            <mat-label>Millesimi Proprietà</mat-label>

            <input matInput placeholder="Millesimi Proprietà" [readonly]="dataservice.currentUser.role < 3" [(ngModel)]="propertyMills" />
        </mat-form-field>

        <div>
            <mat-form-field>
                <mat-label>Codice</mat-label>

                <input matInput placeholder="Codice" [readonly]="dataservice.currentUser.role < 3" [(ngModel)]="currentItem.code" />
            </mat-form-field>
            @if (dataservice.currentUser.role>2) {
            <button mat-icon-button (click)="reCode()">
                <mat-icon class="mat-18">restore_page</mat-icon>
            </button>
            }
        </div>
        <button mat-raised-button [disabled]="!validate()" class="ideb" (click)="saveData()">SALVA</button>
    </div>
</div>
