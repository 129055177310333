<app-progressspinner *ngIf="progress"></app-progressspinner>

<div class="tabcontainer">
    <div class="sectiontitleicon">
        <img src="/assets/concentrator.png" />
        <div>
            <h2 class="tabtitle">
                Concentratori contabilizzatori
            </h2>
            <app-breadcrumbnav [data]="breadcrumb"></app-breadcrumbnav>
        </div>
    </div>

    <div class="formcontainer" *ngIf="this.data">
        <div class="summary">
            <mat-form-field class="field field__find">
                <mat-label>Cerca</mat-label>
                <input matInput placeholder="Cerca" [(ngModel)]="toFind" (ngModelChange)="find()" />
            </mat-form-field>
            <button mat-icon-button (click)="remFind()">
                <mat-icon class="mat-18">delete_forever</mat-icon>
            </button>
            <div>
                <mat-form-field class="field field__find">
                    <mat-label>Stato</mat-label>
                    <mat-select [(ngModel)]="currentStatus" (selectionChange)="onStatusChange()">
                        <mat-option [value]="-1">TUTTI</mat-option>
                        <mat-option [value]="0">INSTALLATI</mat-option>
                        <mat-option [value]="1">ARCHIVIATI</mat-option>
                        <mat-option [value]="2">COLLAUDO</mat-option>
                        <mat-option [value]="3">MAGAZZINO</mat-option>
                        <mat-option [value]="4">CONSEGNATI</mat-option>

                    </mat-select>

                </mat-form-field>
            </div>
            @if (currentStatus<1) {
            <div class="separator"></div>
            <div class="summary__item summary__big" (click)="sel('all')"
                [ngClass]="{ 'summary__item--sel': getTotalSel() }">
                <span class="bullet bullet_white"></span>
                <span>Totali {{dataSource.data.length}}/{{ getTotalLen(data.length) }}</span>
            </div>
            <div class="summary__item" (click)="sel('al')" [ngClass]="{ 'summary__item--sel': fAlive }">
                <span class="bullet bullet_green"></span>
                <span>Aggiornati {{ getTotalLen(alive) }}</span>
            </div>
            <div class="summary__item" (click)="sel('ni')" [ngClass]="{ 'summary__item--sel': fNonInst }">
                <span class="bullet bullet_blue"></span>
                <span>Non installati {{ getTotalLen(notInstalled) }}</span>
            </div>
            <div class="summary__item" (click)="sel('nu')" [ngClass]="{ 'summary__item--sel': fNotUpdated }">
                <span class="bullet bullet_orange"></span>
                <span>Non aggiornati {{ getTotalLen(old) }}</span>
            </div>
            <div class="summary__item" (click)="sel('ol')" [ngClass]="{ 'summary__item--sel': fOffline }">
                <span class="bullet bullet_black"></span>
                <span>Offline {{ getTotalLen(dead) }}</span>
            </div>
        <!--    <div class="summary__item" (click)="sel('arch')" [ngClass]="{ 'summary__item--sel': farchived }">
                <span class="bullet bullet_violet"></span>
                <span>Archiviati {{ archiveds }}</span>
            </div>-->




            <!--       <div class="summary__item" (click)="sel('er')" [ngClass]="{ 'summary__item--sel': fError }">
                <span class="bullet bullet_red"></span>
                <span>Con errori {{ getTotalLen(berror) }}</span>
            </div>
            <div class="summary__item" (click)="sel('der')" [ngClass]="{ 'summary__item--sel': fdError }">
                <span class="bullet bullet_darkgold"></span>
                <span>Dati non validi {{ getTotalLen(derr) }}</span>
            </div>-->
            }

            <div class="summary__sep"></div>
            @if (this.dataservice.currentUser.role === 4) {
            <!--
            <button mat-raised-button (click)="doLabel()">Crea etichette</button>
            <button mat-raised-button (click)="doArchive()" color="warn">Archivia</button>
            <button mat-raised-button (click)="doDaily()" color="primary">Set Daily</button>
            -->
            <button mat-raised-button (click)="doAction()" [disabled]="getSelNumber()===0"
                color="primary">Azioni</button>

            }
            <!--    <mat-button-toggle-group class="summary__btn" name="listDetail" aria-label="Dettagli lista" [(ngModel)]="dataSpecs">
                <mat-button-toggle value="0">Dati</mat-button-toggle>
                <mat-button-toggle value="1">Dettagli</mat-button-toggle>
                <mat-button-toggle value="2">Letture</mat-button-toggle>
                <mat-button-toggle value="3">Valori statistici</mat-button-toggle>
            </mat-button-toggle-group>-->
        </div>
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
            <ng-container matColumnDef="position">
                <th mat-header-cell *matHeaderCellDef>Num. </th>
                <td mat-cell *matCellDef="let element; let i = index" class="cStatus--stat{{element.visualStatus}}">{{
                    dataSource.data.indexOf(element) + 1 }}
                    @if (dataservice.currentUser.role === 4) {
                    <mat-checkbox class="example-margin" [(ngModel)]="element.selected"></mat-checkbox>
                    }
                </td>
            </ng-container>

            <ng-container matColumnDef="selected">
                <th mat-header-cell *matHeaderCellDef>Sel</th>
                <td mat-cell *matCellDef="let element"></td>
            </ng-container>

            <ng-container matColumnDef="mac">
                <th mat-header-cell *matHeaderCellDef>MAC</th>
                <td mat-cell *matCellDef="let element">{{ getMac(element.mac) }}</td>
            </ng-container>


            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef>Stato</th>
                <td mat-cell *matCellDef="let element"><span class="bulletstatus bulletstatus__s{{element.status}}">{{
                        getStatus(element.status) }}</span></td>
            </ng-container>

            <ng-container matColumnDef="imei">
                <th mat-header-cell *matHeaderCellDef>
                    <div>IMEI</div>
                    <div>MSISDN</div>
                </th>
                <td mat-cell *matCellDef="let element">
                    <div>{{ element.imei }}</div>
                    <div>{{ element.msisdn }}</div>
                </td>
            </ng-container>
            <ng-container matColumnDef="imsi">
                <th mat-header-cell *matHeaderCellDef>MSISDN</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.msisdn }}
                </td>
            </ng-container>
            <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef>Descrizione</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.description }}
                </td>
            </ng-container>
            <ng-container matColumnDef="firmware">
                <th mat-header-cell *matHeaderCellDef>FW</th>
                <td mat-cell *matCellDef="let element">
                    <div class="fwstatus">
                        <span>{{ element.firmware }}</span>
                        @if (element.fotaUrl!=="") {
                        <mat-icon aria-hidden="false">upload</mat-icon>
                        }
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="building">
                <th mat-header-cell *matHeaderCellDef>Struttura</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.building?.name }}
                </td>
            </ng-container>

            <ng-container matColumnDef="timing">
                <th mat-header-cell *matHeaderCellDef>Ton/Tint</th>
                <td mat-cell *matCellDef="let element">
                    @if (element.operatingMode===0) {
                    <div>CUSTOM</div>
                    <div>{{ element.readTimer}}/{{ element.sleepTimer}}</div>
                    }
                    @if (element.operatingMode===1) {
                    <div>NEW</div>
                    <div>1/1</div>
                    }
                    @if (element.operatingMode===2) {
                    <div>FAST</div>
                    <div>30/30</div>
                    }
                    @if (element.operatingMode===3) {
                    <div>DAILY</div>
                    <div>{{element.nextUpdate+'Z' | date: 'dd MMM yyyy HH:mm:ss' }}</div>
                    }
                    @if (element.operatingMode===4) {
                    <div>WEEKLY</div>
                    <div>{{element.nextUpdate+'Z' | date: 'dd MMM yyyy HH:mm:ss' }}</div>
                    }
                    @if (element.operatingMode===5) {
                    <div>1d/10d/20d</div>
                    <div>{{element.nextUpdate+'Z' | date: 'dd MMM yyyy HH:mm:ss' }}</div>
                    }


                </td>
            </ng-container>

            <ng-container matColumnDef="company">
                <th mat-header-cell *matHeaderCellDef>Gestore</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.company?.name }}
                </td>
            </ng-container>

            <ng-container matColumnDef="batt">
                <th mat-header-cell *matHeaderCellDef>Batteria</th>
                <td mat-cell *matCellDef="let element">
                    <div class="inline-center">
                        <div>{{ dataservice.getBattVoltage(element) }}%</div> <mat-icon
                            [class]="getBattClass(element)">{{ getBattIcon(element) }}</mat-icon>
                    </div>
                    <!--  <div *ngIf="element.power === 0">N/A</div>
                    <div class="inline-center" *ngIf="element.power === 2">
                        <mat-icon class="batt__ok">power</mat-icon>
                    </div>-->
                </td>
            </ng-container>

            <!--       <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef>Stato</th>
                <td mat-cell *matCellDef="let element">
                    <div class="bulletconc bulletconc__{{element.visualStatus}}">{{getStatusLabel(element)}}</div>
                </td>
            </ng-container>-->

            <ng-container matColumnDef="last">
                <th mat-header-cell *matHeaderCellDef>Ultimo aggiornamento</th>
                <td mat-cell *matCellDef="let element" class="bulletconc bulletconc__{{element.visualStatus}}">
                    <div> {{ element.lastUpdate+ 'Z' | date : 'dd MMM yyyy HH:mm:ss' }}</div>
                    <div>{{getStatusLabel(element)}}</div>
                </td>
            </ng-container>


            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">
                    <a mat-icon-button href="/concentrator/{{element.concentratorId}}"><mat-icon>edit</mat-icon></a>
                    <a mat-icon-button href="/devices/concentrator/{{ element.concentratorId }}"><mat-icon
                            matBadge="{{element.readLen}}">speaker_phone</mat-icon></a>
                    <a mat-icon-button href="/concentrator/logs/{{ element.mac }}"><mat-icon>list_alt</mat-icon></a>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="getColumns()"></tr>
            <tr class="matlinkrow" mat-row *matRowDef="let row; columns: getColumns()" (click)="select(row)"></tr>

            <!--<tr mat-footer-row *matFooterRowDef="getColumns()"></tr>-->
        </table>
        <!--    <mat-paginator [pageSizeOptions]="[10, 20, 100]" showFirstLastButtons aria-label="Seleziona pagina">
        </mat-paginator>-->

    </div>