import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { DataserviceService } from '../dataservice.service';
import { MatAnchor } from '@angular/material/button';

@Component({
    selector: 'app-userverify',
    templateUrl: './userverify.component.html',
    styleUrls: ['./userverify.component.scss'],
    standalone: true,
    imports: [MatAnchor]
})
export class UserverifyComponent implements OnInit {
  response = 0;
    constructor(public dialog: MatDialog, private route: ActivatedRoute, public dataservice: DataserviceService) {
        this.route.paramMap.subscribe(async (map) => {
            // console.log('Pmap; ', map.get('id'));
            const currentId = map.get('id');
            this.dataservice.verifyUser(currentId).subscribe((data) => {
              this.response = data as number;
            });
        });
    }
    ngOnInit(): void {

    }
}
