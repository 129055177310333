<h1 mat-dialog-title *ngIf="action == ''">Utenti dell'appartamento {{ currentApartment.name }}</h1>
<h1 mat-dialog-title *ngIf="action == 'building'">Utenti dello stabile {{ currentBuilding.name }}</h1>
<h1 mat-dialog-title *ngIf="action == 'administrator'">Amministratori stabile {{ currentBuilding.name }}</h1>
<mat-dialog-content>
    <table mat-table [dataSource]="users" class="mat-elevation-z8">
        <ng-container matColumnDef="username">
            <th mat-header-cell *matHeaderCellDef>Nome</th>
            <td mat-cell *matCellDef="let element">{{ element.firstName }}</td>
            <td mat-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Cognome</th>
            <td mat-cell *matCellDef="let element">
                {{ element.lastName }}
            </td>
            <td mat-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="apa">
            <th mat-header-cell *matHeaderCellDef><mat-icon aria-hidden="false">house</mat-icon></th>
            <td mat-cell *matCellDef="let element" [ngClass]="{ bold: element.apartments.length > 0 }">
                {{ element.apartments.length }}
            </td>
            <td mat-cell *matFooterCellDef></td>
        </ng-container>
        <ng-container matColumnDef="build">
            <th mat-header-cell *matHeaderCellDef><mat-icon aria-hidden="false">location_city</mat-icon></th>
            <td mat-cell *matCellDef="let element" [ngClass]="{ bold: element.buildings.length > 0 }">
                {{ element.buildings.length }}
            </td>
            <td mat-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef>Email</th>
            <td mat-cell *matCellDef="let element">
                {{ element.email }}
            </td>
            <td mat-cell *matFooterCellDef></td>
        </ng-container>
        <ng-container matColumnDef="password">
            <th mat-header-cell *matHeaderCellDef class="colPass">Password</th>
            <td mat-cell *matCellDef="let element" class="colPass">
                <button mat-icon-button (click)="resetPass(element)">
                    <mat-icon class="mat-18">restart_alt</mat-icon>
                </button>
            </td>
            <td mat-cell *matFooterCellDef></td>
        </ng-container>
        <ng-container matColumnDef="role">
            <th mat-header-cell *matHeaderCellDef>Ruolo</th>
            <td mat-cell *matCellDef="let element">
                {{ dataservice.getRole(element.role) }}
            </td>
            <td mat-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
                <button *ngIf="element.role < dataservice.currentUser.role" class="button-remove" mat-raised-button (click)="remove(element)">Elimina</button>
            </td>
            <td mat-cell *matFooterCellDef></td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

        <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
    </table>
    <mat-paginator [pageSizeOptions]="[10, 20, 100]" showFirstLastButtons aria-label="Seleziona pagina"> </mat-paginator>
</mat-dialog-content>
