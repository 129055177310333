import { BuildinglistComponent } from './../buildinglist/buildinglist.component';
import { IBreadcrumbData, IBuilding, ICompany, IConcentrator } from './../../interfaces';
import { Component, Inject, OnInit } from '@angular/core';
import { DataserviceService } from '../dataservice.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton, MatButton } from '@angular/material/button';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { FormsModule } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { BreadcrumbnavComponent } from '../breadcrumbnav/breadcrumbnav.component';
import { ProgressspinnerComponent } from '../progressspinner/progressspinner.component';
import { NgIf, NgFor, DatePipe } from '@angular/common';

@Component({
    selector: 'app-concentratordetail',
    templateUrl: './concentratordetail.component.html',
    styleUrls: ['./concentratordetail.component.scss'],
    standalone: true,
    imports: [NgIf, ProgressspinnerComponent, BreadcrumbnavComponent, MatFormField, MatLabel, MatInput, FormsModule, MatSelect, MatOption, MatIconButton, MatIcon, NgFor, MatSlideToggle, MatButton, DatePipe]
})
export class ConcentratordetailComponent implements OnInit {
    currentId = 0;
    breadcrumb: IBreadcrumbData[] = [];
    progress = false;
    constructor(private route: ActivatedRoute, public dialog: MatDialog, public dataservice: DataserviceService) {
        this.progress = true;
        this.route.paramMap.subscribe(async (map) => {
            this.currentId = parseInt(map.get('id'), 10);
            this.dataservice.getConcentrator(this.currentId).subscribe((data) => {
                this.progress = false;
                this.currentItem = data as IConcentrator;
                this.breadcrumb.push({ label: 'HOME', url: '/' });
                this.breadcrumb.push({ label: 'concentratori', url: '/concentrators' });
                this.breadcrumb.push({ label: this.currentItem.description, url: 'javascript:;' });
            });
        });
    }
    currentItem: IConcentrator;
    ngOnInit(): void { }

    changeBatt() {
        this.dataservice.confirm('Vuoi resettare il contatore della batteria?', ['No', 'Si']).then(resp => {
            if (resp == 1) {
                this.currentItem.vbatt = 0;
            }
        })
    }
    changeBuilding() {
        const dialogRef = this.dialog.open(BuildinglistComponent, {
            data: this.currentItem.building,
            width: '90vw',
            height: '90vh'
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                // console.log(result);
                const data = result as IBuilding;

                this.currentItem.building = data;
            }
        });
    }
    validate() {
        return this.currentItem.description !== undefined && this.currentItem.description !== null && this.currentItem.description.trim() !== '';
    }

    saveData() {
        this.progress = true;

        this.dataservice.saveConcentrator(this.currentItem).subscribe((x) => {
            this.progress = false;

            this.dataservice.toastr.success('Salvataggio eseguito');
        });
    }


}
