@if (progress) {
<app-progressspinner></app-progressspinner>
}
@if (building) {
<div class="tabcontainer">
    <div class="sectiontitleicon">
        <img src="/assets/billing.png" />
        <div>
            <h2 class="tabtitle">Lista Bollettazione <span class="titlemark">{{ building.name }}</span></h2>
            <app-breadcrumbnav [data]="breadcrumb"></app-breadcrumbnav>
        </div>
    </div>
    <div class="formcontainer">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
            <ng-container matColumnDef="position">
                <th mat-header-cell *matHeaderCellDef>Num.</th>
                <td mat-cell *matCellDef="let element; let i = index">{{ i + 1 }}</td>
            </ng-container>
            <ng-container matColumnDef="timestamp">
                <th mat-header-cell *matHeaderCellDef>Data bolletta</th>
                <td mat-cell *matCellDef="let element">{{ element.timestamp | date : 'dd MMM yyy HH:mm' }}</td>
            </ng-container>

            <ng-container matColumnDef="created">
                <th mat-header-cell *matHeaderCellDef>Data creazione</th>
                <td mat-cell *matCellDef="let element">{{ element.created | date : 'dd MMM yyy HH:mm' }}</td>
            </ng-container>

            <ng-container matColumnDef="lastmodify">
                <th mat-header-cell *matHeaderCellDef>Ultima modifica</th>
                <td mat-cell *matCellDef="let element">{{ element.lastmodify | date : 'dd MMM yyy HH:mm' }}</td>
            </ng-container>

            <ng-container matColumnDef="published">
                <th mat-header-cell *matHeaderCellDef>Pubblicato</th>
                <td mat-cell *matCellDef="let element">
                    <span *ngIf="!element.draft">{{ element.lastmodify | date : 'dd MMM yyy HH:mm' }}</span><span
                        *ngIf="element.draft">-</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="reference">
                <th mat-header-cell *matHeaderCellDef>Riferimento</th>
                <td mat-cell *matCellDef="let element">
                    {{ getBillStartDate(element.reference) }}
                </td>
            </ng-container>

            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef>Stato</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.draft ? 'DRAFT' : 'PUBBLICATO' }}
                </td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">
                    <a [href]="'/billing/' + element.billingId + '/' + currentId"
                        mat-icon-button><mat-icon>edit</mat-icon></a>
                    <button mat-icon-button (click)="erase(element)"><mat-icon>delete_forever</mat-icon></button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="getColumns()"></tr>
            <tr mat-row *matRowDef="let row; columns: getColumns()"></tr>

            <!--<tr mat-footer-row *matFooterRowDef="getColumns()"></tr>-->
        </table>
        <mat-paginator [pageSizeOptions]="[10, 20, 100]" showFirstLastButtons aria-label="Seleziona pagina">
        </mat-paginator>
    </div>
    <a *ngIf="canAdd()" [href]="'/billing/new/' + currentId" mat-raised-button>Aggiungi bolletta</a>
</div>
}