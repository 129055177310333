import { Component, OnInit } from '@angular/core';
import { DataserviceService } from '../dataservice.service';
import { MatIcon } from '@angular/material/icon';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { NgStyle } from '@angular/common';

@Component({
    selector: 'app-navmenu',
    templateUrl: './navmenu.component.html',
    styleUrls: ['./navmenu.component.scss'],
    standalone: true,
    imports: [NgStyle, RouterLink, RouterLinkActive, MatIcon]
})
export class NavmenuComponent implements OnInit {
    constructor(public dataservice: DataserviceService) {}

    ngOnInit() {

    }
    logout() {
      this.dataservice.logout();
    }
}
