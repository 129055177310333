import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogContent, MatDialogActions } from '@angular/material/dialog';
import { DataserviceService } from '../dataservice.service';
import { MatButton, MatIconButton } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { IMeterDevice, IMeterDeviceStatBillingInfo, IMeterDeviceStatBillingInfoString } from 'src/interfaces';
import { MatRadioButton, MatRadioGroup } from '@angular/material/radio';
import { MatIcon } from '@angular/material/icon';
import { MatOptgroup, MatOption, MatSelect } from '@angular/material/select';

@Component({
    selector: 'app-billcustomstat',
    templateUrl: './billcustomstat.component.html',
    styleUrls: ['./billcustomstat.component.scss'],
    standalone: true,
    imports: [MatOption, MatSelect, MatIconButton, MatIcon, MatRadioButton, MatRadioGroup, MatDialogContent, MatFormField, MatLabel, MatInput, FormsModule, MatDialogActions, MatButton]
})
export class BillcustomstatComponent {
    editValue: IMeterDeviceStatBillingInfoString;
    editDevice: IMeterDevice;
    apartment: string;
    dataType = 1;
    constructor(public dataservice: DataserviceService, public dialogRef: MatDialogRef<BillcustomstatComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
        console.log("aua");
        const stat = (data.stat as IMeterDeviceStatBillingInfo);
        this.editDevice = data.device as IMeterDevice;
        this.apartment = data.apartment as string;
        this.editValue = {
            mm: stat.mm,
            yy: stat.yy,
            value: dataservice.getMediumValue(this.editDevice.medium, stat.value),
            readoutValue: dataservice.getMediumValue(this.editDevice.medium, stat.readoutValue),
            fakeData: dataservice.getMediumValue(this.editDevice.medium, stat.readoutValue),
            dataCalcMode: stat.dataCalcMode
        };

        this.dataType = this.editValue.dataCalcMode;

    }

    saveData() {
        this.editValue.dataCalcMode = this.dataType;
        const tempStat: IMeterDeviceStatBillingInfo = {
            mm: this.editValue.mm,
            yy: this.editValue.yy,
            value: this.dataservice.setMediumValue(this.editDevice.medium, this.editValue.value),
            readoutValue: this.dataservice.setMediumValue(this.editDevice.medium, this.editValue.readoutValue),
            fakeData: this.dataservice.setMediumValue(this.editDevice.medium, this.editValue.readoutValue),
            dataCalcMode: this.editValue.dataCalcMode
        };


        this.dataservice.recalcBillingStat(this.editDevice, tempStat).subscribe(data => {
            this.editValue.value = this.dataservice.getMediumValue(this.editDevice.medium, (data as IMeterDeviceStatBillingInfo).value);
            this.dialogRef.close(this.editValue);
        })
    }

    close() {
        this.dialogRef.close();
    }

    validate() {
        return true;
    }

    reload() {
        this.editValue.dataCalcMode = this.dataType;
        const tempStat: IMeterDeviceStatBillingInfo = {
            mm: this.editValue.mm,
            yy: this.editValue.yy,
            value: this.dataservice.setMediumValue(this.editDevice.medium, this.editValue.value),
            readoutValue: this.dataservice.setMediumValue(this.editDevice.medium, this.editValue.readoutValue),
            fakeData: this.dataservice.setMediumValue(this.editDevice.medium, this.editValue.fakeData),
            dataCalcMode: this.editValue.dataCalcMode
        };
        this.dataservice.recalcBillingStat(this.editDevice, tempStat).subscribe(data => {
            this.editValue.value = this.dataservice.getMediumValue(this.editDevice.medium, (data as IMeterDeviceStatBillingInfo).value);

        })
    }
}
