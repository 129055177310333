import { Component, Inject } from '@angular/core';
import { ProgressspinnerComponent } from '../progressspinner/progressspinner.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogTitle, MatDialogContent, MatDialogActions, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { DataserviceService } from '../dataservice.service';
import { IBuilding, ICSVData, IRowCols } from 'src/interfaces';

@Component({
  selector: 'app-csvexport',
  standalone: true,
  imports: [MatDialogTitle, MatDialogContent, MatDialogActions, MatButtonModule, MatDividerModule, MatIconModule, ProgressspinnerComponent],
  templateUrl: './csvexport.component.html',
  styleUrl: './csvexport.component.scss'
})
export class CsvexportComponent {
  progress = false;
  body: ICSVData;
  content: IRowCols[] = [];
  header: string[];
  constructor(public dataservice: DataserviceService, public dialogRef: MatDialogRef<CsvexportComponent>, @Inject(MAT_DIALOG_DATA) public data: ICSVData) {
    console.log('start');
    this.body = data;
    const lines = this.body.content.split('\n');
    let first = true;;
    for (const line of lines) {
      const columns = line.split(';');

      if (first) {
        this.header = columns;
        first = false;
      } else {
        this.content.push({ cols: columns });
      }
    }
    this.progress = false;


  }
  close() {
    this.dialogRef.close();
  }

  save() {
    const a = document.createElement('a');
    const blob = new Blob([this.body.content], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);

    a.href = url;
    a.download = this.body.filename;
    a.click();
    window.URL.revokeObjectURL(url);  
  }
}
