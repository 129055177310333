<app-progressspinner *ngIf="progress"></app-progressspinner>

<div class="tabcontainer">
    <div class="sectiontitleicon">
        <img src="/assets/buildings.svg" />
        <h2 class="tabtitle">Parametri radiatori</h2>
    </div>
    <div class="summary">
        <mat-form-field class="field field__find">
            <mat-label>Cerca</mat-label>
            <input matInput placeholder="Cerca" [(ngModel)]="toFind" (ngModelChange)="find()" />
        </mat-form-field>
    </div>
    <table mat-table matSort [dataSource]="dataSource" class="mat-elevation-z8" (matSortChange)="SortChange($event)">
        <ng-container matColumnDef="code">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>Codice</th>
            <td mat-cell *matCellDef="let element">
                <div *ngIf="!element.editing">{{ element.code }}</div>
                <mat-form-field *ngIf="element.editing" class="field__std">
                    <mat-label>Codice</mat-label>
                    <input matInput placeholder="Codice" [(ngModel)]="element.code" />
                </mat-form-field>
            </td>
            <td mat-footer-cell *matFooterCellDef>
                <mat-form-field class="field__std">
                    <mat-label>Codice</mat-label>
                    <input matInput placeholder="Codice" [(ngModel)]="newItem.code" />
                </mat-form-field>
            </td>
        </ng-container>

        <ng-container matColumnDef="type">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>Tipo</th>
            <td mat-cell *matCellDef="let element">
                <div *ngIf="!element.editing">{{ element.type }}</div>
                <mat-form-field *ngIf="element.editing" class="field__std">
                    <mat-label>Tipo</mat-label>
                    <input matInput placeholder="Tipo" [(ngModel)]="element.type" />
                </mat-form-field>
            </td>
            <td mat-footer-cell *matFooterCellDef>
                <mat-form-field class="field__std">
                    <mat-label>Tipo</mat-label>
                    <input matInput placeholder="Tipo" [(ngModel)]="newItem.type" />
                </mat-form-field>
            </td>
        </ng-container>

        <ng-container matColumnDef="uni">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>UNI</th>
            <td mat-cell *matCellDef="let element">
                <div *ngIf="!element.editing">{{ element.uni }}</div>
                <mat-form-field *ngIf="element.editing" class="field__num">
                    <mat-label>UNI</mat-label>
                    <input matInput placeholder="UNI" [(ngModel)]="element.uni" />
                </mat-form-field>
            </td>
            <td mat-footer-cell *matFooterCellDef>
                <mat-form-field class="field__num">
                    <mat-label>UNI</mat-label>
                    <input matInput placeholder="UNI" [(ngModel)]="newItem.uni" />
                </mat-form-field>
            </td>
        </ng-container>

        <ng-container matColumnDef="cj">
            <th mat-header-cell *matHeaderCellDef>Cj</th>
            <td mat-cell *matCellDef="let element">
                <div *ngIf="!element.editing">{{ element.cj }}</div>
                <mat-form-field *ngIf="element.editing" class="field__num">
                    <mat-label>Cj</mat-label>
                    <input matInput placeholder="Cj" [(ngModel)]="element.cj" />
                </mat-form-field>
            </td>
            <td mat-footer-cell *matFooterCellDef>
                <mat-form-field class="field__num">
                    <mat-label>Cj</mat-label>
                    <input matInput placeholder="Cj" [(ngModel)]="newItem.cj" />
                </mat-form-field>
            </td>
        </ng-container>
        <ng-container matColumnDef="k75_2f_205">
            <th mat-header-cell *matHeaderCellDef>k75_2f_205</th>
            <td mat-cell *matCellDef="let element">
                <div *ngIf="!element.editing">{{ element.k75_2f_205 }}</div>
                <mat-form-field *ngIf="element.editing" class="field__num">
                    <mat-label>k75_2f_205</mat-label>
                    <input matInput placeholder="k75_2f_205" [(ngModel)]="element.k75_2f_205" />
                </mat-form-field>
            </td>
            <td mat-footer-cell *matFooterCellDef>
                <mat-form-field class="field__num">
                    <mat-label>k75_2f_205</mat-label>
                    <input matInput placeholder="k75_2f_205" [(ngModel)]="newItem.k75_2f_205" />
                </mat-form-field>
            </td>
        </ng-container>
        <ng-container matColumnDef="k75_2f_205_SR">
            <th mat-header-cell *matHeaderCellDef>k75_2f_205_SR</th>
            <td mat-cell *matCellDef="let element">
                <div *ngIf="!element.editing">{{ element.k75_2f_205_SR }}</div>
                <mat-form-field *ngIf="element.editing" class="field__num">
                    <mat-label>k75_2f_205_SR</mat-label>
                    <input matInput placeholder="k75_2f_205_SR" [(ngModel)]="element.k75_2f_205_SR" />
                </mat-form-field>
            </td>
            <td mat-footer-cell *matFooterCellDef>
                <mat-form-field class="field__num">
                    <mat-label>k75_2f_205_SR</mat-label>
                    <input matInput placeholder="k75_2f_205_SR" [(ngModel)]="newItem.k75_2f_205_SR" />
                </mat-form-field>
            </td>
        </ng-container>

        <ng-container matColumnDef="k50_2f_205">
            <th mat-header-cell *matHeaderCellDef>k50_2f_205</th>
            <td mat-cell *matCellDef="let element">
                <div *ngIf="!element.editing">{{ element.k50_2f_205 }}</div>
                <mat-form-field *ngIf="element.editing" class="field__num">
                    <mat-label>k50_2f_205</mat-label>
                    <input matInput placeholder="k50_2f_205" [(ngModel)]="element.k50_2f_205" />
                </mat-form-field>
            </td>
            <td mat-footer-cell *matFooterCellDef>
                <mat-form-field class="field__num">
                    <mat-label>k50_2f_205</mat-label>
                    <input matInput placeholder="k50_2f_205" [(ngModel)]="newItem.k50_2f_205" />
                </mat-form-field>
            </td>
        </ng-container>

        <ng-container matColumnDef="sontexKC1">
            <th mat-header-cell *matHeaderCellDef>sontexKC1</th>
            <td mat-cell *matCellDef="let element">
                <div *ngIf="!element.editing">{{ element.sontexKC1 }}</div>
                <mat-form-field *ngIf="element.editing" class="field__num">
                    <mat-label>sontexKC1</mat-label>
                    <input matInput placeholder="sontexKC1" [(ngModel)]="element.sontexKC1" />
                </mat-form-field>
            </td>
            <td mat-footer-cell *matFooterCellDef>
                <mat-form-field class="field__num">
                    <mat-label>sontexKC1</mat-label>
                    <input matInput placeholder="sontexKC1" [(ngModel)]="newItem.sontexKC1" />
                </mat-form-field>
            </td>
        </ng-container>

        <ng-container matColumnDef="sontexKC2">
            <th mat-header-cell *matHeaderCellDef>sontexKC2</th>
            <td mat-cell *matCellDef="let element">
                <div *ngIf="!element.editing">{{ element.sontexKC2 }}</div>
                <mat-form-field *ngIf="element.editing" class="field__num">
                    <mat-label>sontexKC2</mat-label>
                    <input matInput placeholder="sontexKC2" [(ngModel)]="element.sontexKC2" />
                </mat-form-field>
            </td>
            <td mat-footer-cell *matFooterCellDef>
                <mat-form-field class="field__num">
                    <mat-label>sontexKC2</mat-label>
                    <input matInput placeholder="sontexKC2" [(ngModel)]="newItem.sontexKC2" />
                </mat-form-field>
            </td>
        </ng-container>

        <ng-container matColumnDef="sontexKCSR">
            <th mat-header-cell *matHeaderCellDef>sontexKCSR</th>
            <td mat-cell *matCellDef="let element">
                <div *ngIf="!element.editing">{{ element.sontexKCSR }}</div>
                <mat-form-field *ngIf="element.editing" class="field__num">
                    <mat-label>sontexKCSR</mat-label>
                    <input matInput placeholder="sontexKCSR" [(ngModel)]="element.sontexKCSR" />
                </mat-form-field>
            </td>
            <td mat-footer-cell *matFooterCellDef>
                <mat-form-field class="field__num">
                    <mat-label>sontexKCSR</mat-label>
                    <input matInput placeholder="sontexKCSR" [(ngModel)]="newItem.sontexKCSR" />
                </mat-form-field>
            </td>
        </ng-container>

        <ng-container matColumnDef="image">
            <th mat-header-cell *matHeaderCellDef>image</th>
            <td mat-cell *matCellDef="let element">
                <div *ngIf="!element.editing">{{ element.image }}</div>
                <mat-form-field *ngIf="element.editing" class="field__num">
                    <mat-label>image</mat-label>
                    <input matInput placeholder="image" [(ngModel)]="element.image" />
                </mat-form-field>
            </td>
            <td mat-footer-cell *matFooterCellDef>
                <mat-form-field class="field__num">
                    <mat-label>image</mat-label>
                    <input matInput placeholder="image" [(ngModel)]="newItem.image" />
                </mat-form-field>
            </td>
        </ng-container>

        <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
                <button mat-icon-button *ngIf="!element.editing" (click)="element.editing = true" matTooltip="Modifica"><mat-icon>edit</mat-icon></button>
                <button mat-icon-button *ngIf="element.editing" (click)="confirm(element)" matTooltip="Conferma"><mat-icon>check_outline</mat-icon></button>
            </td>
            <td mat-footer-cell *matFooterCellDef>
                <button mat-icon-button matTooltip="Modifica" (click)="add()"><mat-icon>add_circle</mat-icon></button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>

        <!--<tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>-->
    </table>
    <mat-paginator [pageSizeOptions]="[10, 20, 100]" showFirstLastButtons aria-label="Seleziona pagina"> </mat-paginator>
    <button mat-raised-button (click)="save()">Salva</button>
</div>
