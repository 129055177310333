<div class="pagecontainer">
    <div class="tabcontainer">
        <h2 class="tabtitle">HOME</h2>
        <div class="thumbcontainer">
            <app-menutab *ngIf="dataservice.currentUser?.apartments.length > 0" [badge]="dataservice.currentUser?.apartments.length" caption="Appartamenti" link="/apartments/my" color="white" bkcolor="darkorchid" icon="house"> </app-menutab>
            <app-menutab *ngIf="dataservice.currentUser?.buildings.length > 0" [badge]="dataservice.currentUser?.buildings.length" caption="Stabili" link="/buildings" color="white" bkcolor="wheat" icon="apartment"></app-menutab>
            <app-menutab caption="Profilo" link="/profile" color="white" bkcolor="aquamarine" icon="account_box"> </app-menutab>
            <app-menutab caption="Aiuto" link="/help" color="white" bkcolor="salmon" icon="help"> </app-menutab>
        </div>
    </div>
    @if (stats) {
    <div class="tabcontainer" *ngIf="dataservice.currentUser?.role > 2">
        <h2 class="tabtitle">Contabilizzatori</h2>
        <div class="thumbcontainer">
            <app-menutab [badge]="stats.totalDevices" caption="Totali" link="#" color="white" bkcolor="lightblue"> </app-menutab>
            <app-menutab [badge]="stats.errorDevices" caption="Con errori" link="#" color="white" bkcolor="lightred"> </app-menutab>
            <app-menutab [badge]="stats.updatedDevices" caption="Aggiornati" link="#" color="white" bkcolor="lightgreen"> </app-menutab>
            <app-menutab [badge]="stats.totalDevices-stats.updatedDevices" caption="NON aggiornati" link="#" color="white" bkcolor="orange"> </app-menutab>
            <app-menutab [badge]="stats.orphanMeters" caption="Orfani" link="#" color="white" bkcolor="palevioletred">
            </app-menutab>
        </div>
        <h2 class="tabtitle">Concentratori</h2>
        <div class="thumbcontainer">
            <app-menutab [badge]="stats.totalConcentrators" caption="Totali" link="#" color="white" bkcolor="lightblue"> </app-menutab>
            <app-menutab [badge]="stats.errorConcentrators" caption="Con errori" link="#" color="white" bkcolor="lightred"> </app-menutab>
            <app-menutab [badge]="stats.updatedConcentrators" caption="Aggiornati" link="#" color="white" bkcolor="lightgreen"> </app-menutab>
            <app-menutab [badge]="stats.notUpdatedConcentrators" caption="NON aggiornati" link="#" color="white" bkcolor="orange"> </app-menutab>
            <app-menutab [badge]="stats.concentratorsArchived" caption="Archiviati" link="#" color="white" bkcolor="palevioletred"> </app-menutab>
            <app-menutab [badge]="stats.concentratorsInTest" caption="Collaudo" link="#" color="white" bkcolor="coral"> </app-menutab>
            <app-menutab [badge]="stats.concentratorsReady" caption="In magazzino" link="#" color="white" bkcolor="gray"> </app-menutab>
            <app-menutab [badge]="stats.concentratorShipped" caption="Consegnati" link="#" color="white" bkcolor="lightgray"> </app-menutab>
            

        </div>
    </div>
}
</div>
