import { ISysUser, IUserProfile } from 'src/interfaces';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { DataserviceService } from '../dataservice.service';
import { FormsModule } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatAnchor, MatButton } from '@angular/material/button';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-changepass',
    templateUrl: './changepass.component.html',
    styleUrls: ['./changepass.component.scss'],
    standalone: true,
    imports: [NgIf, MatAnchor, MatFormField, MatLabel, MatInput, FormsModule, MatButton]
})
export class ChangepassComponent implements OnInit {
    code = '';
    codevalid = 0;
    editedUser: IUserProfile;
    confirmPassword = '';
    errorCode = 0;
    constructor(private route: ActivatedRoute, public dialog: MatDialog, public dataservice: DataserviceService) {
        this.route.paramMap.subscribe(async (map) => {
            this.code = map.get('code');
        });
    }

    validate() {
        if (this.editedUser.newpassword.length < 8) {
            this.errorCode = 1;
            return false;
        }

        if (this.editedUser.newpassword !== this.confirmPassword) {
            this.errorCode = 2;
            return false;
        }
        this.errorCode = 0;
        return true;
    }
    saveProfile() {

    }

    ngOnInit(): void {
        if (this.code) {
            this.dataservice.verifyCode(this.code).subscribe(
                () => {
                    this.codevalid = 1;
                },
                () => {
                    this.codevalid = -1;
                }
            );
        } else {
            this.codevalid = 2;
        }
    }
}
