import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource, MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow } from '@angular/material/table';
import { ICompany } from 'src/interfaces';
import { DataserviceService } from '../dataservice.service';
import { MatDialog } from '@angular/material/dialog';
import { CdialogComponent } from '../cdialog/cdialog.component';
import { MatIcon } from '@angular/material/icon';
import { MatButton, MatIconButton } from '@angular/material/button';
import { ProgressspinnerComponent } from '../progressspinner/progressspinner.component';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-company',
    templateUrl: './company.component.html',
    styleUrls: ['./company.component.scss'],
    standalone: true,
    imports: [NgIf, ProgressspinnerComponent, MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatButton, MatIconButton, MatIcon, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow, MatPaginator]
})
export class CompanyComponent implements OnInit {
  companies: ICompany[];
  dataSource: MatTableDataSource<ICompany>;
  progress = false;
  displayedColumns = ['position', 'name', 'contact', 'code', 'action'];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  constructor(public dataservice: DataserviceService, public dialog: MatDialog) {
  }
  async ngOnInit(): Promise<void> {
    this.dataservice.getCompanies().subscribe(data => {
      this.dataSource = new MatTableDataSource<ICompany>(data as ICompany[]);
      this.dataSource.paginator = this.paginator;
    });

  }

  edit(item: ICompany) {
    const dialogRef = this.dialog.open(CdialogComponent, {
      data: item,
      width: '800px',
      height: '480px'
    });

    dialogRef.afterClosed().subscribe((result) => {

      this.ngOnInit();
    });
  }


  regenerateCode(item: ICompany) {
    item.code = this.dataservice.getRandom(12);
  }

  delete(item: ICompany) {

  } addItem() {
    const dialogRef = this.dialog.open(CdialogComponent, {
      data: { name: '' },
      width: '800px',
      height: '480px'
    });

    dialogRef.afterClosed().subscribe((result) => {

      this.ngOnInit();
    });

  }
}
