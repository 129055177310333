import { AfterViewInit, Component, Renderer2 } from '@angular/core';
import { DataserviceService } from './dataservice.service';
import { LoaderService } from './loader.service';
import { RouterOutlet } from '@angular/router';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { NavmenuComponent } from './navmenu/navmenu.component';
import {MatSidenavModule} from '@angular/material/sidenav';
import localeDeAt from '@angular/common/locales/it';
import { registerLocaleData } from '@angular/common';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [NavmenuComponent, ToolbarComponent, RouterOutlet, MatSidenavModule]
})
export class AppComponent implements AfterViewInit {
    title = 'ipnetgreen-frontend';
    constructor(private loaderService: LoaderService, private renderer: Renderer2) {

        registerLocaleData(localeDeAt, 'it');
    }

    ngAfterViewInit() {
        this.loaderService.httpProgress().subscribe((status: boolean) => {
            // // console.log('xxxxxxxxxxxxxxxxxxxxxxx');
            if (status) {
                this.renderer.addClass(document.body, 'cursor-loader');
            } else {
                this.renderer.removeClass(document.body, 'cursor-loader');
            }
        });
    }
}
