import { Component, Input } from '@angular/core';
import { IBreadcrumbData } from 'src/interfaces';
import { NgFor } from '@angular/common';

@Component({
    selector: 'app-breadcrumbnav',
    templateUrl: './breadcrumbnav.component.html',
    styleUrls: ['./breadcrumbnav.component.scss'],
    standalone: true,
    imports: [NgFor]
})
export class BreadcrumbnavComponent {
    @Input() data: IBreadcrumbData[];
}
