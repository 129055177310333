import { DataserviceService } from './../dataservice.service';
import { ISysUser } from './../../interfaces';
import { Component, OnInit } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton, MatButton } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel, MatSuffix } from '@angular/material/form-field';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-useradd',
    templateUrl: './useradd.component.html',
    styleUrls: ['./useradd.component.scss'],
    standalone: true,
    imports: [NgIf, MatFormField, MatLabel, MatInput, FormsModule, MatIconButton, MatSuffix, MatIcon, MatButton]
})
export class UseraddComponent implements OnInit {
    user: ISysUser = { firstName: '', lastName: '', password: '', code: '', device: '' } as ISysUser;
    passwordConfirm = '';
    userConfirm = 0;
    vhide = true;
    hide = true;

    constructor(public dataservice: DataserviceService) { }

    validate() {
        if (this.user.password.length < 8) {
            return false;
        }
        if (this.user.password !== this.passwordConfirm) {
            return false;
        }

        if (this.user.firstName.trim() === '' || this.user.lastName.trim() === '') {
            return false;
        }

        if (this.user.code && this.user.code.trim() === '') {
            return false;
        }

        if (this.user.device && this.user.device.trim() === '') {
            return false;
        }

        return true;
    }

    ngOnInit(): void { }
    saveData() {
        this.dataservice.addUser(this.user).subscribe(
            () => {
                this.dataservice.toastr.success('Utente creato');
                this.userConfirm = 1;
            },
            () => {
                this.userConfirm = 2;
            }
        );
    }
}
