import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogTitle, MatDialogContent, MatDialogActions } from '@angular/material/dialog';
import { IBuilding, IBuildingDataExport, IMeterDeviceStats } from 'src/interfaces';
import { DataserviceService } from '../dataservice.service';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { ProgressspinnerComponent } from "../progressspinner/progressspinner.component";

@Component({
  selector: 'app-exportreads',
  standalone: true,
  templateUrl: './exportreads.component.html',
  styleUrl: './exportreads.component.scss',
  imports: [MatDialogTitle, MatDialogContent, MatDialogActions, MatButtonModule, MatDividerModule, MatIconModule, ProgressspinnerComponent]
})

export class ExportreadsComponent {
  months: IMeterDeviceStats[] = [];
  export: IBuildingDataExport[] = [];
  headers = [
    'CONDOMINIO',
    'APPARTAMENTO',
    'RADIATORE',
    'COGNOME',
    'SCALA',
    'PIANO',
    'LOCALE',
    'MATRICOLA',
    'UNITACONSUMATE',
    'K RADIATORE',
    'LETTURA ELABORATA',
  ];
  selected: IMeterDeviceStats;
  progress = true;
  constructor(public dataservice: DataserviceService, public dialogRef: MatDialogRef<ExportreadsComponent>, @Inject(MAT_DIALOG_DATA) public data: IBuilding) {
    this.dataservice.getExportBuildingFrom(data).subscribe(data => {
      let mm = (data as IMeterDeviceStats).mm;
      let yy = (data as IMeterDeviceStats).yy;
      for (let i = 0; i < 12; i++) {
        this.months.push({ mm: mm, yy: yy } as IMeterDeviceStats);
        mm--;
        if (mm < 0) {
          mm = 11;
          yy--;
        }
      }
      this.progress = false;
    })

  }

  setMonth(item: IMeterDeviceStats) {
    this.progress = true;

    this.selected = item;
    var monthFrom2k = (item.yy - 2000) * 12 + item.mm;
    this.dataservice.getExportBuilding(this.data, monthFrom2k).subscribe(data => {
      this.progress = false;

      this.export = data as IBuildingDataExport[];
    }, () => {
      this.export = [];
      this.progress = false;
    });
  }

  close() {
    this.dialogRef.close();
  }

  exportCSV() {

    const csvData = [this.headers.join(';')];


    for (const item of this.export) {
      csvData.push([item.buildingName, item.apaNumber, item.devNumber, item.surname, item.staircase, item.floor, item.loc, item.serial, item.readout, item.k.toFixed(0), item.value].join(';'));
    }

    const csvArray = csvData.join('\r\n');

    const a = document.createElement('a');
    const blob = new Blob([csvArray], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);

    a.href = url;
    a.download = this.data.name + "_" + (this.dataservice.monthNamesIt[this.selected.mm]) + '_' + this.selected.yy + ".csv";
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }
}
