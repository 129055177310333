import { IMeterDeviceStats, IRadiatorParameter } from './../../interfaces';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogTitle, MatDialogContent, MatDialogActions } from '@angular/material/dialog';
import { IMeterDevice } from 'src/interfaces';
import { BuildingComponent } from '../building/building.component';
import { DataserviceService } from '../dataservice.service';
import { MatButton } from '@angular/material/button';
import { MatInput } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatCard, MatCardHeader, MatCardSubtitle, MatCardContent } from '@angular/material/card';
import { MatIcon } from '@angular/material/icon';
import { MatOption } from '@angular/material/core';
import { NgFor, NgIf, DatePipe } from '@angular/common';
import { MatAutocomplete, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatTabsModule } from '@angular/material/tabs';
@Component({
    selector: 'app-heatdetail',
    templateUrl: './heatdetail.component.html',
    styleUrls: ['./heatdetail.component.scss'],
    standalone: true,
    imports: [MatTabsModule, MatAutocomplete, NgFor, MatOption, NgIf, MatIcon, MatDialogTitle, MatDialogContent, MatCard, MatCardHeader, MatCardSubtitle, MatCardContent, MatFormField, MatLabel, MatSelect, FormsModule, MatInput, MatAutocompleteTrigger, MatButton, MatDialogActions, DatePipe]
})
export class HeatdetailComponent implements OnInit {
    currentItem: IMeterDevice;
    findSerials: IMeterDevice[];
    modified = false;
    constructor(public dataservice: DataserviceService, public dialogRef: MatDialogRef<BuildingComponent>, @Inject(MAT_DIALOG_DATA) public data: IMeterDevice) {
        this.currentItem = { ...data };
        if (this.currentItem.mediumArea === 0) {
            this.currentItem.mediumArea = this.currentItem.medium;
        }
        if (this.dataservice.dictionary === undefined) {
            this.dataservice.getDictionary();
        }
        if (this.dataservice.radiators === undefined) {
            this.dataservice.getRadiators().subscribe((rdata) => {
                this.dataservice.radiators = rdata as IRadiatorParameter[];
            });
        }


    }


    sortedStats() {
        if (this.currentItem.statistics) {
            return this.currentItem.statistics.sort((a, b) => {
                return this.dataservice.compareStat(a, b, false);
            })
        } else {
            return [];
        }
    }

    changeRadiator() {
        this.modified = true;
    }

    reloadK() {
        this.dataservice.recalcK(this.currentItem).subscribe(data => {
            this.currentItem = data as IMeterDevice;
            this.modified = false;
        });

    }

    getStatData(meter: IMeterDevice) {
        const last = meter.statistics.find(x => x.mm === new Date(meter.statisticalDate).getMonth() && x.yy === new Date(meter.statisticalDate).getFullYear());
        if (last) {
            return last.value;
        }
        return -1;
    }

    find() {
        this.dataservice.findFilterDevices(this.currentItem.serial).subscribe((data) => {
            this.findSerials = data as IMeterDevice[];
            // console.log(this.findSerials);
        });
    }

    getHistDate(item: IMeterDeviceStats) {
        if (item) {
            return this.dataservice.monthNamesIt[item.mm] + ' ' + item.yy;
        }
    }

    deleteItem() {
        this.dataservice.confirm('Eliminare il dispositivo?', ['NO', 'SI']).then(x => {
            if (x == 1) {

                this.dataservice.deleteMeters(this.currentItem.meterDeviceId, 'apartment').subscribe(() => {
                    this.dataservice.toastr.success('Eliminazione effettuata');
                    this.dialogRef.close();
                }, () => {
                    this.dataservice.toastr.error('Errore durante l\'eliminazione');
                });

            }
        })
    }
    getIfPart(hist: IMeterDeviceStats) {
        const from2k = (new Date(this.currentItem.statisticalDate).getFullYear() - 2000) * 12 + new Date(this.currentItem.statisticalDate).getMonth();
        return (hist.monthFrom2k > from2k);
    }
    ngOnInit(): void { }

    saveData() {
        this.dialogRef.close(this.currentItem);
    }
    saveDetail() {
        this.dataservice.saveMeter(this.currentItem).subscribe((x) => {
            this.dataservice.toastr.success('Salvataggio eseguito');
            this.dataservice.getMeterDetail(this.currentItem.meterDeviceId).subscribe((data) => {
                this.currentItem = data as IMeterDevice;
            });
        });
    }

    Duplicate() {
        this.dataservice.cloneMeterDetail(this.currentItem).subscribe((x) => {
            this.dataservice.toastr.success('Clone eseguito');
            this.dataservice.getMeterDetail(this.currentItem.meterDeviceId).subscribe((data) => {
                this.currentItem = data as IMeterDevice;
            });
        });
    }

    cancel() {
        this.dialogRef.close();
    }

    validate() {
        return true;
    }


}
