import { DataserviceService } from '../dataservice.service';
import { ICompany, ISysUser } from 'src/interfaces';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource, MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatFooterCellDef, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow, MatFooterRowDef, MatFooterRow } from '@angular/material/table';
import { MatTooltip } from '@angular/material/tooltip';
import { MatIconButton, MatButton } from '@angular/material/button';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { MatIcon } from '@angular/material/icon';
import { FormsModule } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { ProgressspinnerComponent } from '../progressspinner/progressspinner.component';
import { NgIf, NgClass, NgFor } from '@angular/common';

@Component({
    selector: 'app-usermanager',
    templateUrl: './usermanager.component.html',
    styleUrls: ['./usermanager.component.scss'],
    standalone: true,
    imports: [NgIf, ProgressspinnerComponent, MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatFormField, MatLabel, MatInput, FormsModule, MatFooterCellDef, MatIcon, NgClass, MatSelect, NgFor, MatOption, MatIconButton, MatTooltip, MatButton, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow, MatFooterRowDef, MatFooterRow, MatPaginator]
})
export class UsermanagerComponent implements OnInit {
    users: MatTableDataSource<ISysUser>;
    companies: ICompany[] = [];
    selectedUser: ISysUser = { role: 0 } as ISysUser;
    editUser: ISysUser | undefined;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    spin = false;


    displayedColumns: string[] = ['username', 'name', 'email', 'apa', 'build', 'company', 'password', 'role', 'action'];

    constructor(public dataservice: DataserviceService) {}

    ngOnInit() {
        this.spin = true;
        this.dataservice.getUsers(undefined, 0).subscribe((data) => {
            this.users = new MatTableDataSource<ISysUser>(data as ISysUser[]);
            this.spin = false;
        });
        this.dataservice.getCompanies().subscribe((data) => {
            this.companies = data as ICompany[];
        });
        /*  if (this.dataservice.currentUser.role === 3) {
        this.roles.push({ value: 3, viewValue: 'SYS Admin' });
    }*/
    }
    resetPass(user: ISysUser) {}
    select(user: ISysUser) {}

    adduser() {
        // console.log(this.selectedUser);
        if (this.selectedUser.firstName && this.selectedUser.lastName && this.selectedUser.password && this.selectedUser.email) {
            this.dataservice.addUser(this.selectedUser).subscribe(() => {
                this.dataservice.toastr.success('Utente aggiunto');
                this.selectedUser = {} as ISysUser;
                this.dataservice.getUsers(undefined, 0).subscribe((data) => {
                    this.users = new MatTableDataSource<ISysUser>(data as ISysUser[]);
                });
            });
        }
    }
    remove(user: ISysUser) {

        this.dataservice.confirm('Vuoi eliminare ' + user.firstName + ' ' + user.lastName + ' [' + user.email + ']?', ['Si', 'No']).then((res) => {
            if (res === 0) {
                this.dataservice.deleteUser(user).subscribe((data) => {
                    this.dataservice.toastr.success('Utente eliminato');
                    this.ngOnInit();
                });
            }
        });
    }

    save(item: ISysUser) {
        this.dataservice.updateUser(this.editUser).subscribe(
            () => {
                this.dataservice.toastr.success('Aggiornamento riuscito');
                this.ngOnInit();
            },
            () => {
                this.dataservice.toastr.error('Aggiornamento non riuscito');
            }
        );
        this.editUser = undefined;
    }

    edit(item: ISysUser) {
        this.editUser = item;
    }
}
