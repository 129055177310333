import { ICompanyStatistics, IMeterDeviceStats } from './../../interfaces';
import { Component, OnInit } from '@angular/core';
import { DataserviceService } from '../dataservice.service';
import { MenutabComponent } from '../menutab/menutab.component';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    standalone: true,
    imports: [NgIf, MenutabComponent]
})
export class HomeComponent implements OnInit {
    stats: ICompanyStatistics = undefined;
    constructor(public dataservice: DataserviceService) {}

    ngOnInit() {
        this.dataservice.getMeterStats().subscribe((data) => {
            this.stats = data as ICompanyStatistics;
        });
    }
}
