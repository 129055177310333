import { Component, Input, OnInit } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { NgIf } from '@angular/common';
import { RouterLink } from '@angular/router';

@Component({
    selector: 'app-menutab',
    templateUrl: './menutab.component.html',
    styleUrls: ['./menutab.component.scss'],
    standalone: true,
    imports: [RouterLink, NgIf, MatIcon]
})
export class MenutabComponent implements OnInit {
    @Input() link: string;
    @Input() caption: string;
    @Input() bkcolor: string;
    @Input() color: string;
    @Input() icon: string;
    @Input() badge: number;
    @Input() subcaption: string;
    constructor() {}

    ngOnInit(): void {}

    getStyle() {
        return {
            color: this.color,
            backgroundColor: this.bkcolor
        };
    }
}
