<app-progressspinner *ngIf="progress"></app-progressspinner>

<div class="tabcontainer">
    <div class="sectiontitleicon">
        <img src="/assets/buildings.svg" />
        <div>
            <h2 class="tabtitle">Gestione Stabili</h2>
            <app-breadcrumbnav [data]="breadcrumb"></app-breadcrumbnav>
        </div>
    </div>
    <div class="summary">
        <mat-form-field class="field field__find">
            <mat-label>Cerca</mat-label>
            <input matInput placeholder="Cerca" [(ngModel)]="toFind" (ngModelChange)="find()" />
            <button mat-icon-button matSuffix (click)="delfind()"><mat-icon>delete</mat-icon></button>
        </mat-form-field>

        <mat-form-field class="field field__find">
            <mat-label>Gestore</mat-label>
            <mat-select [(ngModel)]="company" [compareWith]="dataservice.compareByCompanyId" (selectionChange)="find()">
                <mat-option [value]="undefined">Tutti</mat-option>
                @for(company of dataservice.companies; track company) {
                <mat-option [value]="company">{{ company.name
                    }}</mat-option>
                }
            </mat-select>
        </mat-form-field>
    </div>
    <table mat-table matSort [dataSource]="dataSource" class="mat-elevation-z8" (matSortChange)="SortChange($event)">
        <ng-container matColumnDef="name">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>Nome</th>
            <td mat-cell *matCellDef="let element">{{ element.name }}</td>
        </ng-container>

        <ng-container matColumnDef="address">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>Indirizzo</th>
            <td mat-cell *matCellDef="let element">
                {{ getAddress(element) }}
            </td>
        </ng-container>
        <ng-container matColumnDef="number">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>Numero</th>
            <td mat-cell *matCellDef="let element">
                {{ element.buildingNumber }}
            </td>
        </ng-container>
        <ng-container matColumnDef="code">
            <th mat-header-cell *matHeaderCellDef>Codice</th>
            <td mat-cell *matCellDef="let element">
                {{ element.code }}
            </td>
        </ng-container>

        <ng-container matColumnDef="devices">
            <th mat-header-cell *matHeaderCellDef>Dispositivi</th>
            <td mat-cell *matCellDef="let element">
                {{ element.heatDeviceType }}
            </td>
        </ng-container>

        <ng-container matColumnDef="company">
            <th mat-header-cell *matHeaderCellDef>Gestore</th>
            <td mat-cell *matCellDef="let element">
                {{ element.company?.name }}
            </td>
        </ng-container>

        <ng-container matColumnDef="note">
            <th mat-header-cell *matHeaderCellDef>Note</th>
            <td mat-cell *matCellDef="let element">
                {{ element.type }}
            </td>
        </ng-container>

        <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">

                <a href="/apartments/{{ element.buildingId }}" mat-icon-button matTooltip="Unità abitative"><mat-icon
                        [matBadge]="element.apartmentsCount">apartment</mat-icon></a>
                @if (dataservice.currentUser.role>2) {
                <a mat-icon-button href="/devices/building/{{ element.buildingId }}"
                    matTooltip="Contabilizzatori"><mat-icon
                        [matBadge]="element.metersCount">speaker_phone</mat-icon></a>


                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                    <mat-icon>more_vert</mat-icon>
                </button>

                <mat-menu #menu="matMenu">
                    <a mat-icon-button href="/building/{{ element.buildingId }}"
                        matTooltip="Modifica"><mat-icon>edit</mat-icon></a>
                    <a href="/apartments/{{ element.buildingId }}" mat-icon-button
                        matTooltip="Unità abitative"><mat-icon
                            [matBadge]="element.apartmentsCount">apartment</mat-icon></a>
                    <a mat-icon-button href="/devices/building/{{ element.buildingId }}"
                        matTooltip="Contabilizzatori"><mat-icon
                            [matBadge]="element.metersCount">speaker_phone</mat-icon></a>
                    <button mat-icon-button (click)="showuser(element, false)"
                        matTooltip="Utenti"><mat-icon>people_alt</mat-icon></button>
                    <button mat-icon-button (click)="showuser(element, true)" matTooltip="Amministratori"><mat-icon
                            color="warn">people_alt</mat-icon></button>
                    <a href="/coverage/{{ element.buildingId }}" mat-icon-button
                        matTooltip="Copertura antenne"><mat-icon color="warn">wifi_find</mat-icon></a>
                    <button mat-icon-button (click)="delete(element)" matTooltip="Elimina stabile"><mat-icon
                            color="warn">delete_forever</mat-icon></button>
                    <a href="/billings/{{ element.buildingId }}" mat-icon-button matTooltip="Bollettazione"><mat-icon
                            color="primary">euro</mat-icon></a>

                    <button mat-icon-button (click)="exportdata(element)" matTooltip="Esporta letture"><mat-icon
                            color="primary">file_download</mat-icon></button>
                    <button (click)="exportBuildiongData(element)" mat-icon-button
                        matTooltip="Salva condominio in CSV"><mat-icon color="primary">save</mat-icon></button>
                    <button mat-raised-button
                        (click)="dataservice.copy(element.code)">{{element.code}}<mat-icon>content_copy</mat-icon></button>
                </mat-menu>
                }
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="select(row)"></tr>

        <!--<tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>-->
    </table>
    <mat-paginator [pageSizeOptions]="[10, 20, 100]" showFirstLastButtons aria-label="Seleziona pagina">
    </mat-paginator>
    <div class="actions mtop20">
        <button mat-raised-button (click)="addNew()">Aggiungi</button>
        <button mat-raised-button (click)="import()">Importa...</button>
    </div>
</div>