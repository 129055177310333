import { IMeterDeviceStats } from './../../interfaces';
import { AdddevicesComponent } from './../adddevices/adddevices.component';
import { DataserviceService } from './../dataservice.service';
import { Component, OnInit } from '@angular/core';
import { IApartment, IGraphData, IMeterDevice } from 'src/interfaces';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { PieChartModule, BarChartModule } from '@swimlane/ngx-charts';
import { FormsModule } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { MatFormField } from '@angular/material/form-field';
import { NgIf, NgFor, NgClass, DatePipe } from '@angular/common';

@Component({
    selector: 'app-myapartment',
    templateUrl: './myapartment.component.html',
    styleUrls: ['./myapartment.component.scss'],
    standalone: true,
    imports: [NgIf, MatFormField, MatInput, FormsModule, NgFor, NgClass, PieChartModule, BarChartModule, DatePipe]
})
export class MyapartmentComponent implements OnInit {
    currentItem = { meterDevices: [] } as IApartment;
    currentId: number;
    selectetHeat: any;
    stats: IMeterDeviceStats[];
    pieDatas: { [key: number]: IGraphData[] } = {};
    barDatas: { [key: number]: IGraphData[] } = {};

    view: any[] = [700, 300];
    colorScheme = {
        // domain: ['#5AA454', '#E44D25', '#CFC0BB', '#7aa3e5', '#a8385d', '#aae3f5']
        domain: ['#003f5c', '#2f4b7c', '#665191', '#a05195', '#d45087', '#f95d6a', '#ff7c43', '#ffa600']
    };
    // options
    legend = false;
    showLabels = true;
    animations = true;
    xAxis = true;
    yAxis = true;
    showYAxisLabel = true;
    showXAxisLabel = false;

    showXAxis = true;
    showYAxis = true;
    xAxisLabel = 'Mese';
    yAxisLabel = 'Consumo';
    showXAxisLabelBar = 'Mese';
    timeline = false;

    gradient = true;
    showLegend = false;
    isDoughnut = false;
    legendPosition = 'below';
    metersType: number[] = [];

    constructor(public dialog: MatDialog, private route: ActivatedRoute, public dataservice: DataserviceService) {
        this.route.paramMap.subscribe(async (map) => {
            // console.log('Pmap; ', map.get('id'));
            this.currentId = parseInt(map.get('id'), 10);
            this.refresh();
        });
    }
    ngOnInit(): void { }
    getMeters(mtype: number) {
        return this.currentItem.meterDevices.filter(x => x.medium === mtype);
    }
    refresh() {
        this.dataservice.getApartmet(this.currentId).subscribe(
            (data) => {
                this.currentItem = data as IApartment;
                // this.getStats();
                this.metersType = [];
                for (const dev of this.currentItem.meterDevices) {
                    if (this.metersType.indexOf(dev.medium) === -1) {
                        this.metersType.push(dev.medium);
                        this.pieDatas[dev.medium] = this.getData(dev.medium);
                        this.barDatas[dev.medium] = this.getBarData(dev.medium);
                    }
                }
            },
            () => {
                this.dataservice.goHome();
            }
        );
    }

    edit(device: IMeterDevice) {
        this.dataservice.editLabel('Aggiorna nome', 'nome', device.name).then((data) => {
            // console.log('Updated:', data);
            device.name = data as string;
            this.dataservice.saveMeterName(device).subscribe(() => {
                this.dataservice.toastr.success('Aggiornamento eseguito');
                this.refresh();
            });
        });
    }

    validate() {
        return true;
    }
    getLastMonth() {
        if (this.currentItem.meterDevices != null) {
            if (this.currentItem.meterDevices.length > 0) {
                return this.currentItem.meterDevices[0].statisticalDate;
            }
        }
    }
    getLastMonthValue(medium: number) {
        let val = 0;
        const items = this.currentItem.meterDevices.filter(x => x.medium == medium);
        for (const meter of items) {
            // const last = meter.statistics.find(x => x.mm === new Date(meter.statisticalDate).getMonth() && x.yy === new Date(meter.statisticalDate).getFullYear());

            val += meter.statValue;
        }
        return this.dataservice.getMediumValue(medium, val);
    }

    getActualValue() {
        let val = 0;
        for (const meter of this.currentItem.meterDevices) {
            val += meter.actualValue;
        }
        return val;
    }

    addMeter() {
        const dialogRef = this.dialog.open(AdddevicesComponent, {
            data: this.currentItem,
            width: '90vw',
            height: '90vh'
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                // console.log(result);
                this.refresh();
            }
        });
    }

    /*
    getData() {
        const response: IGraphData[] = [];
        let i = 0;
        for (const meter of this.currentItem.meterDevices) {
            response.push({ key: meter, value: meter.statData, index: i });
            i++;
        }
        return response;
    }
*/

    getData(medium: number) {
        // console.log("--");
        const response: IGraphData[] = [];
        if (this.currentItem.meterDevices) {
            let i = 0;
            for (const meter of this.currentItem.meterDevices.filter(x => x.medium === medium)) {
                let name = meter.name;
                if (name === '' || name === undefined) {
                    name = meter.serial;
                }
                //  const last = meter.statistics.find(x => x.mm === new Date(meter.statisticalDate).getMonth() && x.yy === new Date(meter.statisticalDate).getFullYear());

                response.push({ name, value: this.calcCost(meter), label: 'pippo', order: 0 });
                i++;
            }
        }
        return response;
    }

    getBarData(medium) {
        const data: IGraphData[] = [];
        for (const meter of this.currentItem.meterDevices.filter(x => x.medium === medium)) {
            for (const st of meter.statistics) {
                const month = this.dataservice.monthNamesIt[st.mm] + ' ' + (st.yy - 2000).toString();
                const order = st.yy * 1000 + st.mm;
                const dt = data.find(x => x.order === order);
                if (dt) {
                    dt.value += this.calcStatCost(meter, st);
                    if (dt.value < 0) {
                        dt.value = 0;
                    }

                } else {
                    data.push({ name: month, value: this.calcStatCost(meter, st), order: order, label: '' });
                }
            }
        }
        const sort = data.sort((a, b) => {
            return b.order - a.order;
        });
        console.log(data, sort);
        return sort;
    }

    haveData(medium: number) {
        return this.currentItem.meterDevices.filter(x => x.medium === medium);
    }

    selPie(event) {
        this.selectetHeat = event;
    }

    onSelect(data): void {
        // console.log("data", data);
        if (data) {
            for (const met of this.currentItem.meterDevices) {
                //    // console.log('Test ', met.name, data.value.name);

                if (met.name === data.value.name || met.serial === data.value.name) {
                    this.selectetHeat = met.serial;
                    break;
                }
            }
        }
    }

    calcCost(item: IMeterDevice) {
        return this.dataservice.getDecValue(item, item.statValue);
    }

    calcStatCost(item: IMeterDevice, stat: IMeterDeviceStats) {
        switch (item.medium) {
            case 8:
                return Math.round(stat.deltaValue);
            case 7:
            case 6:
                return stat.readedDeltaValue / 1000;
        }
        return stat.readedDeltaValue;
    }

    onActivate(data): void {
        if (data) {
            for (const met of this.currentItem.meterDevices) {
                //  // console.log('Test ', met.name, data.value.name);

                if (met.name === data.value.name || met.serial === data.value.name) {
                    this.selectetHeat = met.serial;
                    break;
                }
            }
        }
    }

    onDeactivate(data): void {
        this.selectetHeat = undefined;
    }
}
