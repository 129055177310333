import { DataserviceService } from './../dataservice.service';
import { Component, Inject, OnInit } from '@angular/core';
import { IApartment, IBreadcrumbData } from 'src/interfaces';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { UserlistdialogComponent } from '../userlistdialog/userlistdialog.component';
import { FormsModule } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatBadge } from '@angular/material/badge';
import { MatIcon } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';
import { MatIconAnchor, MatIconButton, MatButton } from '@angular/material/button';
import { NgIf } from '@angular/common';
import { BreadcrumbnavComponent } from '../breadcrumbnav/breadcrumbnav.component';
import { MatCheckbox } from '@angular/material/checkbox';

@Component({
    selector: 'app-apartment',
    templateUrl: './apartment.component.html',
    styleUrls: ['./apartment.component.scss'],
    standalone: true,
    imports: [MatCheckbox, BreadcrumbnavComponent, NgIf, MatIconAnchor, MatTooltip, MatIcon, MatBadge, MatIconButton, MatFormField, MatLabel, MatInput, FormsModule, MatButton]
})
export class ApartmentComponent implements OnInit {
    heatMills = 0;
    energyMills = 0;
    propertyMills = 0;
    currentItem: IApartment;
    breadcrumb: IBreadcrumbData[] = [];
    currentId = 0;
    progress = false;
    constructor(public dialog: MatDialog, private route: ActivatedRoute, public dataservice: DataserviceService, public dialogRef: MatDialogRef<ApartmentComponent>, @Inject(MAT_DIALOG_DATA) public data: IApartment) {
        this.route.paramMap.subscribe(async (map) => {
            // console.log('Pmap; ', map.get('id'));
            this.currentId = parseInt(map.get('id'), 10);
            this.progress = true;
            this.dataservice.getApartmet(this.currentId).subscribe((apa) => {
                this.currentItem = apa as IApartment;
                this.heatMills = this.currentItem.heatMills / 100;
                this.energyMills = this.currentItem.energyMills / 100;
                this.propertyMills = this.currentItem.propertyMills / 100;
                this.breadcrumb.push({ label: 'HOME', url: '/' });
                this.breadcrumb.push({ label: 'stabili', url: '/buildings' });
                this.breadcrumb.push({ label: this.currentItem.building.name, url: '/apartments/' + this.currentItem.building.buildingId });
                this.breadcrumb.push({ label: this.currentItem.name, url: 'javascript:;' });
            });
        });
    }

    ngOnInit(): void { }

    saveData() {
        this.currentItem.heatMills = Math.round(this.heatMills * 100);
        this.currentItem.energyMills = Math.round(this.energyMills * 100);
        this.currentItem.propertyMills = Math.round(this.propertyMills * 100);
        this.dataservice.saveApartment(this.currentItem).subscribe((x) => {
            this.dataservice.toastr.success('Salvatagio eseguito');
        });
    }

    showuser(item: IApartment) {
        const dialogRef = this.dialog.open(UserlistdialogComponent, {
            data: { apartment: item }
        });

        dialogRef.afterClosed().subscribe((result) => { });
    }

    validate() {
        return true;
    }

    reCode() {
        this.currentItem.code = this.dataservice.getRandomNumber(10);
    }
}
