import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogTitle, MatDialogContent, MatDialogActions } from '@angular/material/dialog';
import { ICompany } from 'src/interfaces';
import { DataserviceService } from '../dataservice.service';
import { BuildingComponent } from '../building/building.component';
import { MatButton } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel } from '@angular/material/form-field';

@Component({
    selector: 'app-cdialog',
    templateUrl: './cdialog.component.html',
    styleUrl: './cdialog.component.scss',
    standalone: true,
    imports: [MatDialogTitle, MatDialogContent, MatFormField, MatLabel, MatInput, FormsModule, MatDialogActions, MatButton]
})
export class CdialogComponent {
  currentItem: ICompany;
  modified = false;
  isNew = false;
  constructor(public dataservice: DataserviceService, public dialogRef: MatDialogRef<BuildingComponent>, @Inject(MAT_DIALOG_DATA) public data: ICompany) {
    this.currentItem = { ...data };
    this.isNew = this.currentItem.name === '';

  }
  cancel() {
    this.dialogRef.close();
  }

  saveDetail() {
    if (this.isNew) {
      this.dataservice.addCompanies(this.currentItem).subscribe(data => {
        this.dialogRef.close();
      }, () => {
        this.dataservice.toastr.error('Errore durante la creazione della Gestore');
        this.dialogRef.close();

      });
    } else {
      this.dataservice.updateCompanies(this.currentItem).subscribe(data => {
        this.dialogRef.close();
      }, () => {
        this.dataservice.toastr.error('Errore durante la creazione della Gestore');
        this.dialogRef.close();

      });
    }
  }

}
