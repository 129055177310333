import { Component, OnInit } from '@angular/core';
import { DataserviceService } from '../dataservice.service';
import { NgIf, NgClass } from '@angular/common';
import { MatToolbar, MatToolbarRow } from '@angular/material/toolbar';

@Component({
    selector: 'app-toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss'],
    standalone: true,
    imports: [MatToolbar, MatToolbarRow, NgIf, NgClass]
})
export class ToolbarComponent implements OnInit {
    constructor(public dataservice: DataserviceService) { }

    ngOnInit() { }

    getRoleStyle() {
        if (this.dataservice.currentUser) {
            switch (this.dataservice.currentUser.role) {
                case 0:
                    return 'userrole userrole--user';
                case 1:
                case 2:
                    return 'userrole userrole--user';
                case 3:
                    return 'userrole userrole--admin';
                case 4:
                    return 'userrole userrole--sysadmin';
            }
        }
    }

    getRoleName() {
        if (this.dataservice.currentUser) {
            switch (this.dataservice.currentUser.role) {
                case 0:
                    return 'GUEST';
                case 1:
                case 2:
                    return 'USER';
                case 3:
                    return 'ADMIN ' + this.dataservice.currentUser.company.name;
                case 4:
                    return 'SYSADMIN';
            }
        }
    }
}
