import { IBuilding } from './../../interfaces';
import { IApartment, ISysUser } from 'src/interfaces';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogTitle, MatDialogContent } from '@angular/material/dialog';
import { DataserviceService } from '../dataservice.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource, MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatFooterCellDef, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow, MatFooterRowDef, MatFooterRow } from '@angular/material/table';
import { MatIconButton, MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { NgIf, NgClass } from '@angular/common';

@Component({
    selector: 'app-userlistdialog',
    templateUrl: './userlistdialog.component.html',
    styleUrls: ['./userlistdialog.component.scss'],
    standalone: true,
    imports: [NgIf, MatDialogTitle, MatDialogContent, MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatFooterCellDef, MatIcon, NgClass, MatIconButton, MatButton, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow, MatFooterRowDef, MatFooterRow, MatPaginator]
})
export class UserlistdialogComponent implements OnInit {
    currentApartment: IApartment;
    currentBuilding: IBuilding;
    action = '';
    displayedColumns: string[] = ['username', 'name', 'email', 'password', 'role', 'action'];
    @ViewChild(MatPaginator) paginator: MatPaginator;
    users: MatTableDataSource<ISysUser>;
    constructor(public dataservice: DataserviceService, public dialogRef: MatDialogRef<UserlistdialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
        this.currentApartment = data.apartment;
        if (data.building) {
            this.action = 'building';
            this.currentBuilding = data.building;
        }
        if (data.administrator) {
            this.action = 'administrator';
            this.currentBuilding = data.administrator;
        }
    }

    ngOnInit() {
        if (this.currentApartment) {
            this.dataservice.getUsers('apartment', this.currentApartment.apartmentId).subscribe((dt) => {
                this.users = new MatTableDataSource<ISysUser>(dt as ISysUser[]);

                // // console.log('ap', dt);
            });
        }
        if (this.currentBuilding) {
            this.dataservice.getUsers(this.action, this.currentBuilding.buildingId).subscribe((dt) => {
                //  // console.log('bu', dt);

                this.users = new MatTableDataSource<ISysUser>(dt as ISysUser[]);
            });
        }
    }

    resetPass(element) {}

    remove(user: ISysUser) {
        this.dataservice.confirm('Vuoi eliminare ' + user.firstName + ' ' + user.lastName + ' [' + user.email + ']?', ['Si', 'No']).then((res) => {
            if (res === 0) {
                this.dataservice.deleteUser(user).subscribe((data) => {
                    this.dataservice.toastr.success('Utente eliminato');
                    this.ngOnInit();
                });
            }
        });
    }
}
