<ul class="nav navbar-nav mainnav">
    <img class="logo" src="assets/geco2.png" />
    <hr />
    <div class="welcome">
        <div>Ciao <span>{{ dataservice.currentUser?.firstName }}</span></div>
    </div>

    <li [ngStyle]="dataservice.getAuth(true, 0, 0)" [routerLink]="['/']" [routerLinkActive]="['link-active']"
        [routerLinkActiveOptions]="{ exact: true }"><mat-icon aria-hidden="false">home</mat-icon>
        <div> Home</div>
    </li>
    <li [ngStyle]="dataservice.getAuth(true, 0, 0)" [routerLink]="['/profile']" [routerLinkActive]="['link-active']"
        [routerLinkActiveOptions]="{ exact: true }"><mat-icon aria-hidden="false">face</mat-icon>
        <div>Profilo</div>
    </li>

    <li [ngStyle]="dataservice.getAuth(true, 3, 0)" [routerLink]="['/buildings']" [routerLinkActive]="['link-active']"
        [routerLinkActiveOptions]="{ exact: true }"><mat-icon aria-hidden="false">location_city</mat-icon>
        <div>Stabili</div>
    </li>
    <!--<li [ngStyle]="dataservice.getAuth(true, 0, 0)" [routerLink]="['/apartments/my']" [routerLinkActive]="['link-active']" [routerLinkActiveOptions]="{ exact: true }"><mat-icon aria-hidden="false">house</mat-icon><div>Unità abitative</l</div>i>-->
    <li [ngStyle]="dataservice.getAuth(true, 3, 0)" [routerLink]="['/concentrators']"
        [routerLinkActive]="['link-active']" [routerLinkActiveOptions]="{ exact: true }"><mat-icon
            aria-hidden="false">memory</mat-icon>
        <div>Concentratori</div>
    </li>
    <li [ngStyle]="dataservice.getAuth(false, 0, 0)" [routerLink]="['/login']" [routerLinkActive]="['link-active']"
        [routerLinkActiveOptions]="{ exact: true }"><mat-icon aria-hidden="false">how_to_reg</mat-icon>
        <div>Login</div>
    </li>
    <li [ngStyle]="dataservice.getAuth(false, 0, 0)" [routerLink]="['/signup']" [routerLinkActive]="['link-active']"
        [routerLinkActiveOptions]="{ exact: true }"><mat-icon aria-hidden="false">person_add</mat-icon>
        <div>Registrati</div>
    </li>
    <li [ngStyle]="dataservice.getAuth(true, 2, 0)" [routerLink]="['/users']" [routerLinkActive]="['link-active']"
        [routerLinkActiveOptions]="{ exact: true }"><mat-icon aria-hidden="false">account_box</mat-icon>
        <div>Utenti</div>
    </li>
    <li [ngStyle]="dataservice.getAuth(true, 4, 0)" [routerLink]="['/radiators']" [routerLinkActive]="['link-active']"
        [routerLinkActiveOptions]="{ exact: true }"><mat-icon aria-hidden="false">luggage</mat-icon>
        <div>Radiatori</div>
    </li>
    <li [ngStyle]="dataservice.getAuth(true, 2, 0)" [routerLink]="['/devices/all/0']"
        [routerLinkActive]="['link-active']" [routerLinkActiveOptions]="{ exact: true }"><mat-icon
            aria-hidden="false">speaker_phone</mat-icon>
        <div>Contabilizzatori</div>
    </li>
    <li [ngStyle]="dataservice.getAuth(true, 2, 0)" [routerLink]="['/logs']" [routerLinkActive]="['link-active']"
        [routerLinkActiveOptions]="{ exact: true }"><mat-icon aria-hidden="false">view_list</mat-icon>
        <div>Log eventi</div>
    </li>
    <li [ngStyle]="dataservice.getAuth(true, 2, 0)" [routerLink]="['/help']" [routerLinkActive]="['link-active']"
        [routerLinkActiveOptions]="{ exact: true }"><mat-icon aria-hidden="false">help_outline</mat-icon>
        <div>Assistenza</div>
    </li>
    <li [ngStyle]="dataservice.getAuth(true, 3, 0)" [routerLink]="['/utils']" [routerLinkActive]="['link-active']"
        [routerLinkActiveOptions]="{ exact: true }"><mat-icon aria-hidden="false">handyman</mat-icon>
        <div>Utilità</div>
    </li>
    <li [ngStyle]="dataservice.getAuth(true, 3, 0)" [routerLink]="['/companies']" [routerLinkActive]="['link-active']"
        [routerLinkActiveOptions]="{ exact: true }"><mat-icon aria-hidden="false">account_balance</mat-icon>
        <div>Gestore</div>
    </li>
    <li [ngStyle]="dataservice.getAuth(true, 0, 0)" (click)="logout()"><mat-icon
            aria-hidden="false">exit_to_app</mat-icon>
        <div>Logout</div>
    </li>
</ul>