import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogContent, MatDialogActions } from '@angular/material/dialog';
import { MatButton } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { MatFormField } from '@angular/material/form-field';

@Component({
    selector: 'app-editlabel',
    templateUrl: './editlabel.component.html',
    styleUrls: ['./editlabel.component.scss'],
    standalone: true,
    imports: [MatDialogContent, MatFormField, MatInput, FormsModule, MatDialogActions, MatButton]
})
export class EditlabelComponent implements OnInit {
    title: string;
    label: string;
    value: string;

    constructor(public dialogRef: MatDialogRef<EditlabelComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
        this.title = data.title;
        this.label = data.label;
        this.value = data.value;
    }

    ngOnInit(): void {}

    close() {
        this.dialogRef.close(null);
    }
    save() {
        this.dialogRef.close(this.value);
    }
}
