<app-progressspinner *ngIf="progress"></app-progressspinner>

<div class="tabcontainer">
    <h2 class="tabtitle">Menutenzione</h2>
    <button mat-raised-button (click)="updateK('rebuild', 'Ricostruzione contatori')">Ricostruisci contatori</button>
    <button mat-raised-button *ngIf="dataservice.getPermission(true, 4)"
        (click)="updateK('restat', 'Aggiornamento valori K')">Aggiorna valori K</button>
    <button mat-raised-button *ngIf="dataservice.getPermission(true, 4)"
        (click)="updateK('delempt', 'Eliminazione appartamenti 0')">Elimina orfani</button>
    <button mat-raised-button *ngIf="dataservice.getPermission(true, 4)"
        (click)="updateK('rebuildradiator', 'Ricostruisci indici radiatori')">Ricorstruisci
        parametri radiatori</button>
    <button mat-raised-button *ngIf="dataservice.getPermission(true, 4)"
        (click)="updateK('millsround', 'Arrotonda valori millesimi')">Arrotonda valori
        millesimi</button>
    <button mat-raised-button *ngIf="dataservice.getPermission(true, 4)"
        (click)="updateK('cleanvalue', 'Ripulisci dati statistici')">Ripulisci dati
        statistici</button>
    <div class="logs" [innerHTML]="logs"></div>
</div>
<div class="tabcontainer">
    <h2 class="tabtitle">Importa letture contabilizzatori (rep)</h2>

    <input type="file" class="file-input" (change)="onFileSelected($event)" #fileUpload />

    <div class="file-upload">
        {{ fileName || 'No file uploaded yet.' }}

        <button mat-mini-fab color="primary" class="upload-btn" (click)="fileUpload.click()">
            <mat-icon>attach_file</mat-icon>
        </button>
    </div>
</div>

<div class="tabcontainer" *ngIf="dataservice.getPermission(true, 4)">
    <h2 class="tabtitle">Importa File Città</h2>

    <input type="file" class="file-input" (change)="onCitySelected($event)" #fileCityUpload />

    <div class="file-upload">
        {{ filCityeName || 'No file uploaded yet.' }}

        <button mat-mini-fab color="primary" class="upload-btn" (click)="fileCityUpload.click()">
            <mat-icon>attach_file</mat-icon>
        </button>
    </div>
</div>

<div class="tabcontainer" *ngIf="dataservice.getPermission(true, 4)">
    <h2 class="tabtitle">Importa File Strade</h2>

    <input type="file" class="file-input" (change)="onStreetSelected($event)" #fileStreetUpload />

    <div class="file-upload">
        {{ filStreetName || 'No file uploaded yet.' }}

        <button mat-mini-fab color="primary" class="upload-btn" (click)="fileStreetUpload.click()">
            <mat-icon>attach_file</mat-icon>
        </button>
    </div>
</div>

<div class="tabcontainer">
    <h2 class="tabtitle">Importa dati appartamenti</h2>

    <input type="file" class="file-input" (change)="onAPFileSelected($event)" #fileApartmentUpload />

    <div class="file-upload">
        {{ fileApartmentName || 'No file uploaded yet.' }}

        <button mat-mini-fab color="primary" class="upload-btn" (click)="fileApartmentUpload.click()">
            <mat-icon>attach_file</mat-icon>
        </button>
    </div>
    <div class="importBuilding">
        <div *ngFor="let build of addBuilding">{{build}} </div>
    </div>
    <div class="importSummary" *ngIf="summaryBuildingImport!==undefined">
        <div class="importSummary__item" *ngFor="let item of summaryBuildingImport.summary">
            <h2>{{item.name}}</h2>
            <table class="importTable">
                <tr>
                    <th>#</th>
                    <th>Seriale</th>
                    <th>Appartamento</th>
                    <th>Nome</th>
                    <th>Nuovo</th>
                    <th>Radiatore</th>


                </tr>
                <tr *ngFor="let device of item.devices; index as i">
                    <td>{{i+1}}</td>
                    <td>{{device.serial}}</td>
                    <td>{{device.apartment}}</td>
                    <td>{{device.location}}</td>
                    <td>
                        {{device.isNew?"Si":"No"}}
                    </td>

                    <td>

                        <div class="importTable__ok" [ngClass]="{'importTable__err':!device.haveParameters}">
                            <mat-icon class="importTable__ok"
                                *ngIf="device.haveParameters">check-haveParameters</mat-icon>
                            <mat-icon class="importTable__err" *ngIf="!device.haveParameters">error</mat-icon>
                            {{device.haveParameters?"Si":"No"}} UNI: {{device.radiatorType}} Tipo:
                            {{device.radiatorCode}}
                        </div>
                    </td>
                </tr>
            </table>

        </div>
        <table class="errorTable">
            <tr>
                <th>Errori</th>
            </tr>
            <tr *ngFor="let item of summaryBuildingImport.errors; index as i">
                <td>{{item}}</td>
            </tr>
            <tr *ngIf="summaryBuildingImport.errors.length==0">
                <td>
                    <div class="importTable__ok">
                        Nessun errore
                    </div>
                </td>
            </tr>
        </table>
    </div>
</div>

<div class="tabcontainer">
    <h2 class="tabtitle">Importa dati stabili</h2>

    <input type="file" class="file-input" (change)="onBuildingFileSelected($event)" #fileBuildingUpload />

    <div class="file-upload">
        {{ fileBuildingName || 'No file uploaded yet.' }}

        <button mat-mini-fab color="primary" class="upload-btn" (click)="fileBuildingUpload.click()">
            <mat-icon>attach_file</mat-icon>
        </button>
    </div>
</div>

<div class="tabcontainer" *ngIf="dataservice.getPermission(true, 4)">
    <h2 class="tabtitle">Importa tipi radiatori</h2>

    <input type="file" class="file-input" (change)="onRadFileSelected($event)" #fileRadUpload />

    <div class="file-upload">
        {{ fileRadName || 'No file uploaded yet.' }}

        <button mat-mini-fab color="primary" class="upload-btn" (click)="fileRadUpload.click()">
            <mat-icon>attach_file</mat-icon>
        </button>
    </div>
</div>
<div class="tabcontainer" *ngIf="dataservice.getPermission(true, 4)">
    <h2 class="tabtitle">Assegna stabili a Gestore</h2>

    <mat-form-field class="field">
        <mat-label>Gestore</mat-label>
        <mat-select [(ngModel)]="company" [compareWith]="dataservice.compareByCompanyId">
            <mat-option *ngFor="let company of dataservice.companies" [value]="company">{{ company.name }}</mat-option>
        </mat-select>
    </mat-form-field>
    <button mat-raised-button (click)="reCompany()">Associa</button>
</div>