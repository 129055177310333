import { Component, Inject } from '@angular/core';
import { FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogTitle, MatDialogContent, MatDialogActions } from '@angular/material/dialog';
import { IApartment, ISysUser } from 'src/interfaces';
import { DataserviceService } from '../dataservice.service';
import { MyErrorStateMatcher } from '../profile/profile.component';
import { MatButton } from '@angular/material/button';
import { NgIf } from '@angular/common';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel, MatError } from '@angular/material/form-field';

@Component({
    selector: 'app-passwordrecover',
    templateUrl: './passwordrecover.component.html',
    styleUrls: ['./passwordrecover.component.scss'],
    standalone: true,
    imports: [MatDialogTitle, MatDialogContent, MatFormField, MatLabel, MatInput, FormsModule, ReactiveFormsModule, NgIf, MatError, MatDialogActions, MatButton]
})
export class PasswordrecoverComponent {
    email = '';
    phone = '';
    matcher = new MyErrorStateMatcher();
    emailFormControl = new FormControl('', [Validators.required, Validators.email]);
    phoneFormControl = new FormControl('', [Validators.required, Validators.pattern('[- +()0-9]+')]);
    constructor(public dataservice: DataserviceService, public dialogRef: MatDialogRef<PasswordrecoverComponent>, @Inject(MAT_DIALOG_DATA) public data: IApartment) {}

    validate() {}

    saveData() {
        const user: ISysUser = { email: this.email, phone: this.phone } as ISysUser;
        this.dataservice.recoverUser(user).subscribe(() => {});
        this.dataservice.confirm('È stata inviata una mail all´indirizzo ' + this.email + ' con le istruzioni per cambiare la password', ['OK']).then((data) => {
            this.dialogRef.close();
        });
    }

    close() {
        this.dialogRef.close();
    }
}
