import { ICompany, ISummaryBuildingImport } from './../../interfaces';
import { DataserviceService } from './../dataservice.service';
import { Component } from '@angular/core';
import { MatOption } from '@angular/material/core';
import { FormsModule } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatButton, MatMiniFabButton } from '@angular/material/button';
import { ProgressspinnerComponent } from '../progressspinner/progressspinner.component';
import { NgIf, NgFor, NgClass } from '@angular/common';

@Component({
    selector: 'app-utils',
    templateUrl: './utils.component.html',
    styleUrls: ['./utils.component.scss'],
    standalone: true,
    imports: [NgIf, ProgressspinnerComponent, MatButton, MatMiniFabButton, MatIcon, NgFor, NgClass, MatFormField, MatLabel, MatSelect, FormsModule, MatOption]
})
export class UtilsComponent {
    upkrunning = 0;
    progress = false;
    logs = '';
    fileName = '';
    fileRadName = '';
    fileApartmentName = '';
    fileBuildingName = '';
    filCityeName = '';
    filStreetName = '';
    addBuilding = [];
    company: ICompany;
    summaryBuildingImport: ISummaryBuildingImport;
    constructor(public dataservice: DataserviceService) { }
    updateK(command: string, name: string) {
        this.upkrunning = 1;
        this.progress = true;
        this.dataservice.setUtil(command).subscribe(
            (data) => {
                if ((data as any).message) {
                    this.logs += '<div class="logs--ok">\n comando <b>' + name + '</b> eseguito: ' + (data as any).message + '</div>';
                }
                this.progress = false;
            },
            () => {
                this.logs += '<div class="logs--err">\n comando <b>' + name + '</b> fallito</div>';
                this.progress = false;
            }
        );
    }
    onRadFileSelected(event) {
        const file: File = event.target.files[0];

        if (file) {
            this.fileRadName = file.name;
            const formData = new FormData();
            formData.append('file', file);
            this.progress = true;
            this.dataservice.uploadFile('radparams', formData).subscribe(() => {
                this.progress = false;

                this.dataservice.toastr.success('Caricamento riuscito');
            });
        }
    }

    onCitySelected(event) {
        const file: File = event.target.files[0];

        if (file) {
            this.filCityeName = file.name;
            const formData = new FormData();
            formData.append('file', file);
            this.dataservice.uploadFile('towns', formData).subscribe(() => {
                this.dataservice.toastr.success('Caricamento riuscito');
            });
        }
    }

    onStreetSelected(event) {
        const file: File = event.target.files[0];

        if (file) {
            this.filStreetName = file.name;
            const formData = new FormData();
            formData.append('file', file);
            this.dataservice.uploadFile('streets', formData).subscribe(() => {
                this.dataservice.toastr.success('Caricamento riuscito');
            });
        }
    }

    onFileSelected(event) {
        const file: File = event.target.files[0];

        if (file) {
            this.progress = true;
            this.fileName = file.name;
            const formData = new FormData();
            formData.append('file', file);
            this.dataservice.uploadFile('heats', formData).subscribe(() => {
                this.progress = false;

                this.dataservice.toastr.success('Caricamento riuscito');
            }, () => {
                this.progress = false;
                this.dataservice.toastr.error("Import fallito");

            });
        }
    }

    onAPFileSelected(event) {
        const file: File = event.target.files[0];

        if (file) {
            this.fileApartmentName = file.name;
            const formData = new FormData();
            formData.append('file', file);
            var reader = new FileReader();
            reader.onload = (event) => {
                console.log(event.target.result);
                const lines = event.target.result.toString().split('\n');
                for (let i = 1; i < lines.length; i++) {
                    const columns = lines[i].split(';');
                    if (this.addBuilding.indexOf(columns[3]) === -1) {
                        this.addBuilding.push(columns[3]);
                    }
                }
            }
            reader.readAsText(file)
            /*
                      this.progress = true;
                      this.dataservice.uploadFile('apartments', formData).subscribe((data) => {
                          this.dataservice.toastr.success('Caricamento riuscito');
                          this.summaryBuildingImport = data as ISummaryBuildingImport;
                          this.progress = false;
                      }, () => {
                          this.progress = false;
                          this.dataservice.toastr.error('Caricamento fallito');
          
                      }
          
                      );*/
        }
    }
    onBuildingFileSelected(event) {
        const file: File = event.target.files[0];

        if (file) {
            this.fileBuildingName = file.name;
            const formData = new FormData();
            formData.append('file', file);

            this.dataservice.uploadFile('buildings', formData).subscribe(() => {
                this.dataservice.toastr.success('Caricamento riuscito');
            });
        }
    }

    removeAll() {
        this.dataservice.confirm('Sei sicuro di voler dare una spianata al DB?', ['Si', 'No']).then((resp) => {
            if (resp === 0) {
                this.dataservice.cleanDB().subscribe(
                    (data) => {
                        this.dataservice.toastr.success('Eliminazione eseguita');
                    },
                    () => {
                        this.dataservice.toastr.success('Errore');
                    }
                );
            }
        });
    }

    reCompany() {
        if (this.company) {
            this.dataservice.reCompany(this.company).subscribe(
                () => {
                    this.dataservice.toastr.success('Comando eseguito');
                },
                () => {
                    this.dataservice.toastr.error('Errore');
                }
            );
        }
    }
}
