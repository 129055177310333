import { PasswordrecoverComponent } from './../passwordrecover/passwordrecover.component';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ISysUser } from 'src/interfaces';
import { DataserviceService } from '../dataservice.service';
import { UserlistdialogComponent } from '../userlistdialog/userlistdialog.component';
import { NgIf } from '@angular/common';
import { MatButton, MatAnchor } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { FormsModule } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatCard, MatCardHeader, MatCardContent } from '@angular/material/card';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    standalone: true,
    imports: [MatCard, MatCardHeader, MatCardContent, MatFormField, MatLabel, MatInput, FormsModule, MatCheckbox, MatButton, NgIf, MatAnchor]
})
export class LoginComponent implements OnInit {
    user: ISysUser = { firstName: '', password: '', lastName: '' } as ISysUser;
    recover = false;
    message = '';
    rememberMe = true;
    constructor(public dataservice: DataserviceService, public dialog: MatDialog) { }

    ngOnInit() { }

    login() {
        // console.log('User: ', this.user);
        this.dataservice.login(this.user, this.rememberMe, (error) => {
            // console.log('error', error);
            if (error.status === 401) {
                this.message = 'Nome utente o password errati';
                this.recover = true;
            }
        });
    }

    recoverPass() {
        const dialogRef = this.dialog.open(PasswordrecoverComponent, {});
        dialogRef.afterClosed().subscribe((result) => { });
    }
}
