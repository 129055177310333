import { Component, Inject, OnInit } from '@angular/core';
import { IBuilding, ICompany, IImportDeviceSummary, IMeterDevice, ISummaryBuildingImport } from 'src/interfaces';
import { DataserviceService } from '../dataservice.service';
import { CommonModule } from '@angular/common';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogContent, MatDialogRef } from '@angular/material/dialog';
import { MatRadioModule } from '@angular/material/radio';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { MatExpansionPanel, MatExpansionPanelDescription, MatExpansionPanelHeader, MatExpansionPanelTitle } from '@angular/material/expansion';
import { ProgressspinnerComponent } from '../progressspinner/progressspinner.component';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatOption, MatSelect } from '@angular/material/select';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'app-importapartment',
    standalone: true,
    imports: [CommonModule, MatIconModule, MatDialogContent, MatDialogActions, MatButton, MatRadioModule, MatExpansionPanel, MatExpansionPanelHeader,
        MatExpansionPanelTitle, ProgressspinnerComponent, MatFormField, MatLabel, MatSelect, MatOption, FormsModule, MatExpansionPanelDescription],
    templateUrl: './importapartment.component.html',
    styleUrl: './importapartment.component.scss'
})
export class ImportapartmentComponent implements OnInit {
    errorNameNotMatch = false;
    upkrunning = 0;
    progress = false;
    logs = '';
    fileApartmentName = '';
    addBuilding = [];
    invalidMeters = [];
    totalMeters = 0;
    apartments = [];
    company: ICompany;
    summaryBuildingImport: ISummaryBuildingImport;
    existMeters: IImportDeviceSummary[] = [];
    currentBuilding: IBuilding = undefined;
    buildingsOk = true;
    currentFile: File;
    constructor(public dataservice: DataserviceService, public dialogRef: MatDialogRef<ImportapartmentComponent>, @Inject(MAT_DIALOG_DATA) public data: IBuilding) {
        this.currentBuilding = data;
        if (this.company == undefined) {
            this.company = this.dataservice.companies[0];
        }
        console.log("company", this.company);
    }

    ngOnInit(): void {

    }

    onAPFileSelected(event) {
        this.currentFile = event.target.files[0];

        if (this.currentFile) {
            this.fileApartmentName = this.currentFile.name;
            this.progress = true;
            const formData = new FormData();
            formData.append('file', this.currentFile);

            this.dataservice.uploadFile('apartments/test/' + this.company.companyId, formData).subscribe((data) => {
                console.log(data);
                this.summaryBuildingImport = data as ISummaryBuildingImport;

                for (const build of this.summaryBuildingImport.summary) {
                    if (!build.isNew) {
                        this.buildingsOk = false;
                    }
                    for (const device of build.devices) {
                        if (device.apartment != "") {
                            this.existMeters.push(device);
                        }
                    }
                }
                this.progress = false;
                if (this.summaryBuildingImport.invalidBuilding > 0) {
                    this.buildingsOk = false;
                }
            }, () => {
                this.progress = false;
                this.dataservice.toastr.error('Verifica dati fallita');

            });



            var reader = new FileReader();
            reader.onload = (event) => {
                console.log(event.target.result);
                const lines1 = event.target.result.toString().split('\n');
                const lines2 = event.target.result.toString().split('\r');
                let lines = lines2;
                if (lines1.length > lines2.length) {
                    lines = lines1;
                }
                for (let i = 1; i < lines.length; i++) {
                    const columns = lines[i].split(';');
                    let apartment = '';
                    if (columns[4]) {
                        apartment = columns[4].trim();

                        if (columns[32]) {
                            const serial = columns[32].trim();
                            if (serial.length !== 8) {
                                this.invalidMeters.push(serial + ' ' + apartment + ' ' + columns[14]);
                            }
                        }
                        if (this.apartments.indexOf(columns[4]) === -1 && columns[4].trim() !== '') {
                            this.apartments.push(columns[4]);
                        }
                        if (this.addBuilding.indexOf(columns[3]) === -1 && columns[3].trim() !== '' && this.currentBuilding) {
                            this.addBuilding.push(columns[3]);
                            if (columns[3].trim().toLowerCase() !== this.currentBuilding.name.trim().toLocaleLowerCase()) {
                                this.errorNameNotMatch = true;
                            }
                        }
                    }
                }
            }
            reader.readAsText(this.currentFile)

        }
    }
    cancel() {
        this.dialogRef.close();
    }



    startImport() {
        const formData = new FormData();

        if (this.currentFile) {
            formData.append('file', this.currentFile);
            this.progress = true;
            this.dataservice.uploadFile('apartments/' + this.company.companyId, formData).subscribe((data) => {
                this.progress = false;
                this.dataservice.toastr.success('Import eseguito');
                this.dialogRef.close();
            }, () => {
                this.progress = false;
                this.dataservice.toastr.error('Errore importando i dati');
            });
        }
    }

    getDisabled() {
        if (this.summaryBuildingImport.invalidBuilding > 0) {
            return true;
        }
        if (this.existMeters.length > 0) {
            return true;
        }
        if (this.apartments.length === 0) {
            return true;
        }

    }
}
