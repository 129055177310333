<h2 mat-dialog-header>Lettura manuale {{apartment}} - {{editDevice.serial}} {{editDevice.name}}</h2>
<mat-dialog-content>
    <mat-form-field>
        <mat-label>Forza lettura</mat-label>
        <mat-select [(ngModel)]="dataType">
            <mat-option [value]=0>Dato originale</mat-option>
            <mat-option [value]=1>Simulazione dato letto</mat-option>
            <mat-option [value]=2>Simulazione dato calcolato</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field>
        <mat-label>Valore</mat-label>
        @if (dataType!==0) {
        <input matInput placeholder="Lettura" [(ngModel)]="editValue.fakeData" />
        }
        @else {
        <input matInput placeholder="Lettura" [(ngModel)]="editValue.readoutValue" disabled="1" />

        }
    </mat-form-field>
    <button mat-icon-button (click)="reload()"><mat-icon>restart_alt</mat-icon></button>
    <mat-form-field>
        <input matInput placeholder="Calcolato" [(ngModel)]="editValue.value" disabled="1" />
    </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-raised-button [disabled]="!validate()" class="ideb" (click)="saveData()">SALVA</button>
    <button mat-raised-button class="ideb" (click)="close()">ANNULLA</button>
</mat-dialog-actions>