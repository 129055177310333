import { ConcentratordetailComponent } from './concentratordetail/concentratordetail.component';
import { ApartmentComponent } from './apartment/apartment.component';
import { BuildingComponent } from './building/building.component';
import { BillinglistComponent } from './billinglist/billinglist.component';
import { ChangepassComponent } from './changepass/changepass.component';
import { ManageradiatorlistComponent } from './manageradiatorlist/manageradiatorlist.component';
import { ManageapartmentComponent } from './manageapartment/manageapartment.component';
import { UtilsComponent } from './utils/utils.component';
import { ConcentrarorlogsComponent } from './concentrarorlogs/concentrarorlogs.component';
import { UserverifyComponent } from './userverify/userverify.component';
import { UseraddComponent } from './useradd/useradd.component';
import { MyapartmentComponent } from './myapartment/myapartment.component';
import { DeviceslistComponent } from './managedevices/deviceslist.component';
import { ConcentratorsComponent } from './concentrators/concentrators.component';
import { ManagebuildingComponent } from './managebuilding/managebuilding.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { UsermanagerComponent } from './usermanager/usermanager.component';
import { ProfileComponent } from './profile/profile.component';
import { ProjectsComponent } from './projects/projects.component';
import { LogsComponent } from './logs/logs.component';
import { BillingComponent } from './billing/billing.component';
import { CompanyComponent } from './company/company.component';
import { CoverageComponent } from './coverage/coverage.component';

const routes: Routes = [
    { path: '', component: HomeComponent, pathMatch: 'full' },
    { path: 'login', component: LoginComponent },
    { path: 'signup', component: UseraddComponent },
    { path: 'users', component: UsermanagerComponent },
    { path: 'verify/:id', component: UserverifyComponent },
    { path: 'profile', component: ProfileComponent },
    { path: 'logs', component: LogsComponent },
    { path: 'buildings', component: ManagebuildingComponent },
    { path: 'buildings/:search', component: ManagebuildingComponent },
    { path: 'apartments/:id', component: ManageapartmentComponent },
    { path: 'myapartment/:id', component: MyapartmentComponent },
    { path: 'apartment/:id', component: ApartmentComponent },
    { path: 'coverage/:id', component: CoverageComponent },
    { path: 'devices/:from/:id', component: DeviceslistComponent },
    { path: 'concentrators', component: ConcentratorsComponent },
    { path: 'concentrator/:id', component: ConcentratordetailComponent },
    { path: 'concentrator/logs/:mac', component: ConcentrarorlogsComponent },
    { path: 'utils', component: UtilsComponent },
    { path: 'radiators', component: ManageradiatorlistComponent },
    { path: 'billing/:idb/:id', component: BillingComponent },
    { path: 'billings/:id', component: BillinglistComponent },
    { path: 'companies', component: CompanyComponent },
    { path: 'pass/:code', component: ChangepassComponent },
    { path: 'building/:id', component: BuildingComponent }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule {}
