import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogTitle, MatDialogContent, MatDialogActions } from '@angular/material/dialog';
import { MatButton } from '@angular/material/button';
import { NgFor } from '@angular/common';

@Component({
    selector: 'app-confirmdialog',
    templateUrl: './confirmdialog.component.html',
    styleUrls: ['./confirmdialog.component.scss'],
    standalone: true,
    imports: [MatDialogTitle, MatDialogContent, MatDialogActions, NgFor, MatButton]
})
export class ConfirmdialogComponent implements OnInit {

  text: string;

  constructor(public dialogRef: MatDialogRef<ConfirmdialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    if (this.data.buttons === undefined) {
      this.data.buttons = ['OK', 'Annulla'];
    }
  }

  ngOnInit() {
  }
  btclick(text: string) {
    this.dialogRef.close(this.data.buttons.indexOf(text));
  }
}
