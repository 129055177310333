<app-progressspinner *ngIf="progress"></app-progressspinner>

<div class="tabcontainer">
    <div class="sectiontitleicon">
        <img src="/assets/cover.png" />
        <div>
            <h2 class="tabtitle">
                Visualizzazione stato copertura letture stabile <b>{{currentBuilding.name}}</b>
            </h2>
            <app-breadcrumbnav [data]="breadcrumb"></app-breadcrumbnav>
        </div>
    </div>
    <div class="summary">
        <mat-form-field class="field field__find">
            <mat-label>Concentratori</mat-label>
            <mat-select [(ngModel)]="currentConcentrator" [formControl]="control">
                <mat-option value="All">Tutti</mat-option>
                <mat-option *ngFor="let item of concentrators" [value]="item.mac">{{item.mac}}
                    {{item.description}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="coverage">
        <table class="coverage">
            <tr>
                <th>
                    Piano
                </th>
                <th *ngFor="let item of staircase">Scala: {{item}}</th>
            </tr>
            <tr *ngFor="let floor of floors">
                <td class="coverage__td">
                    <div>Piano {{floor }}</div>
                </td>
                <td class="coverage__td" *ngFor="let stair of staircase">

                    <div *ngFor="let apa of getApas(stair, floor)" [matTooltip]="getApaTooltip(apa)"
                        matTooltipClass="coverage__tooltip"
                        class="coverage__apartment coverage__apartment{{getApaClass(apa)}}">
                        {{apa.apartmentNumber}} &nbsp;{{apa.name}}
                    </div>
                </td>

            </tr>
        </table>       
    </div>
</div>