<div class="tabcontainer">
    <h2 class="tabtitle">Concentratori Contabilizzatori calore</h2>
        <div class="error" *ngIf="errorCode===-1">Il codice non è valido o è scaduto, per favore ripeti la procedura di recupero password<br />
        <a mat-raised-button href="/login">Vai al Login</a>
        </div>

    <div *ngIf="errorCode===1">
        <!--     <mat-form-field class="field-full-width">
            <mat-label>Password corrente</mat-label>
            <input matInput placeholder="Password corrente" type="password" [(ngModel)]="editedUser.oldpassword" />
        </mat-form-field>-->
        <mat-form-field class="field-full-width">
            <mat-label>Nuova password</mat-label>

            <input matInput placeholder="Nuova password" type="password" [(ngModel)]="editedUser.newpassword" />
        </mat-form-field>
        <mat-form-field class="field-full-width">
            <mat-label>Conferma password</mat-label>

            <input matInput placeholder="Conferma nuova password" type="password" [(ngModel)]="confirmPassword" />
        </mat-form-field>
        <button mat-raised-button [disabled]="!validate()" class="ideb" (click)="saveProfile()">SALVA</button>
    </div>
</div>
