import { IConcentrator, IConcentratorLog } from './../../interfaces';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataserviceService } from '../dataservice.service';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource, MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow } from '@angular/material/table';
import { MatButton } from '@angular/material/button';
import { ProgressspinnerComponent } from '../progressspinner/progressspinner.component';
import { NgIf, DatePipe } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
    selector: 'app-concentrarorlogs',
    templateUrl: './concentrarorlogs.component.html',
    styleUrls: ['./concentrarorlogs.component.scss'],
    standalone: true,
    imports: [MatTooltipModule, MatIcon, NgIf, ProgressspinnerComponent, MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow, MatPaginator, MatButton, DatePipe]
})
export class ConcentrarorlogsComponent implements OnInit {
    logs: IConcentratorLog[] = [];
    dataSource: MatTableDataSource<IConcentratorLog>;
    displayedColumns = ['position', 'timestamp', 'lasterror', 'vbatt', 'interval', 'readed', 'total', 'actions'];
    @ViewChild(MatPaginator) paginator: MatPaginator;
    currentItem: IConcentrator | undefined;
    progress = false;
    constructor(private route: ActivatedRoute, public dialog: MatDialog, public dataservice: DataserviceService) {
        this.route.paramMap.subscribe(async (map) => {
            // console.log('Pmap; ', map.get('mac'));
            const currentId = map.get('mac');
            this.progress = true;

            this.dataservice.getConcentratorLogs(currentId).subscribe(
                (data) => {
                    this.progress = false;

                    this.logs = data as IConcentratorLog[];
                    this.dataSource = new MatTableDataSource<IConcentratorLog>(this.getFilteredDevices());
                    this.dataSource.paginator = this.paginator;
                },
                () => {
                    this.logs = [];
                    this.progress = false;

                }
            );
        });
    }
    getFilteredDevices() {
        return this.logs;
    }
    ngOnInit(): void { }

    archive() { }

    reloadLog(item: IConcentratorLog) {
        this.dataservice.confirm('Ricaricare la lettura selezionata?', ['Si', 'No']).then(res => {
            if (res === 0) {
                this.dataservice.resendConcentratorLog(item).subscribe(() => {
                    this.dataservice.toastr.success('Operazione eseguita');
                }, () => {
                    this.dataservice.toastr.error('Esecuzione fallita');
                })
            }
        })
    }
}
