@if (progress) {
<app-progressspinner></app-progressspinner>
}
<h2 mat-dialog-title>Esporta letture <b>{{data.name}}</b></h2>
<mat-dialog-content>
    <div class="months">
        @for (item of months; track $index) {
        <button mat-raised-button class="months__month" color="{{(item==selected)?'primary':''}}"
            (click)="setMonth(item)">{{dataservice.monthNamesIt[item.mm]}}&nbsp;{{item.yy}}</button>
        }
    </div>
    @if (export!==undefined) {
    <table>
        <tr>
            @for (item of headers; track $index) {
            <th>{{item}}</th>
            }

        </tr>
        @for (item of export; track $index) {
        <tr>
            <td>{{item.buildingName}}</td>
            <td>{{item.apaNumber}}</td>
            <td>{{item.devNumber}}</td>
            <td>{{item.surname}}</td>
            <td>{{item.staircase}}</td>
            <td>{{item.floor}}</td>
            <td>{{item.loc}}</td>
            <td>{{item.serial}}</td>
            <td>{{item.readout}}</td>
            <td>{{item.k.toFixed(2)}}</td>
            <td><b>{{item.value}}</b></td>
        </tr>
        }

    </table>
    }
    @else
    {
    <div>Nessun dato presente, seleziona un mese come riferimento</div>
    }
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-raised-button (click)="close()">Chiudi</button>
    <button mat-raised-button (click)="exportCSV()" [disabled]="export.length===0">Esporta CSV</button>
</mat-dialog-actions>