<mat-toolbar class="maintool">
    <mat-toolbar-row class="toolbar">
        <h2 class="toptitle">Gestione</h2>
        <div class="spacer"></div>
        <div class="spacer"></div>
        <img *ngIf="dataservice.currentUser !== undefined" class="icons" src="assets/profile_white.svg" />
        <div>&nbsp;{{ dataservice.currentUser?.firstName }}&nbsp;</div>
        <div [ngClass]="getRoleStyle()">{{ getRoleName() }}</div>
        <!-- <div class="bullet" [ngClass]="{ 'bullet--good': dataservice.connected }"></div>
        <div class="connstat" *ngIf="dataservice.connected">Connesso al server</div>
        <div class="connstat" *ngIf="!dataservice.connected">Disconnesso dal server</div>-->
    </mat-toolbar-row>
</mat-toolbar>
