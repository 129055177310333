<app-progressspinner *ngIf="progress"></app-progressspinner>

<div class="tabcontainer">
    <div class="sectiontitleicon">
        <img src="/assets/concentrator.png" />
        <div>
            <h2 class="tabtitle">
                Concentratore [<b>{{ currentItem?.description }}</b>]
            </h2>
            <app-breadcrumbnav [data]="breadcrumb"></app-breadcrumbnav>
        </div>
    </div>

    <div *ngIf="currentItem" class="formcontainer">
        <mat-form-field class="field-full-width">
            <mat-label>Descrizione</mat-label>

            <input matInput placeholder="Descrizione" [(ngModel)]="currentItem.description" />
        </mat-form-field>

        <mat-form-field class="field">
            <mat-label>Modo INSTALLATO</mat-label>
            <mat-select [(ngModel)]="currentItem.operatingMode">
                <mat-option [value]="0">Personalizzato</mat-option>
                <mat-option [value]="1">Collaudo</mat-option>
                <mat-option [value]="2">Veloce 30 min / 30 min</mat-option>
                <mat-option [value]="3">Giornaliero</mat-option>
                <mat-option [value]="4">Settimanale</mat-option>
                <mat-option [value]="5">1 - 10 - 20 del mese</mat-option>

            </mat-select>
        </mat-form-field>
        <mat-form-field class="field">
            <mat-label>Durata</mat-label>
            <input matInput type="number" placeholder="Durata lettura" [(ngModel)]="currentItem.readTimer"
                [disabled]="currentItem.operatingMode!==0" />
        </mat-form-field>
        <mat-form-field class="field">
            <mat-label>Intervallo</mat-label>
            <input matInput type="number" placeholder="Intervallo lettura" [(ngModel)]="currentItem.sleepTimer"
                [disabled]="currentItem.operatingMode!==0" />
        </mat-form-field>
        <mat-form-field class="field">
            <mat-label>FOTA url</mat-label>
            <input matInput placeholder="FOTA Url" [(ngModel)]="currentItem.fotaUrl" />
        </mat-form-field>
        <!-- <mat-form-field class="field">
            <mat-label>Tensione alimentazione</mat-label>
            <mat-select [(ngModel)]="currentItem.power">
                <mat-option [value]="0">N/A</mat-option>
                <mat-option [value]="1">2xD Pack 7.2V</mat-option>
                <mat-option [value]="2">AC Adapter</mat-option>
            </mat-select>
        </mat-form-field>-->



        <table>
            <tr>
                <td>MAC</td>
                <td>{{ currentItem.mac }}</td>
            </tr>
            <tr>
                <td>Firmware</td>
                <td>{{ currentItem.firmware }}</td>
            </tr>
            <tr>
                <td>IMEI</td>
                <td>{{ currentItem.imei }}</td>
            </tr>
            <tr>
                <td>Operatore</td>
                <td>{{ currentItem.carrier }}</td>
            </tr>
            <tr>
                <td>Vbatt</td>
                <td>{{ dataservice.getBattVoltage(currentItem) }}% <button mat-button
                        (click)="changeBatt()">Resetta</button></td>
            </tr>
            <tr>
                <td>Ultimo aggiornamento</td>
                <td>{{ currentItem.lastUpdate | date }}</td>
            </tr>
            <tr>
                <td>Struttura</td>
                <td>
                    {{ currentItem.building?.name }}
                    <button mat-icon-button (click)="changeBuilding()">
                        <mat-icon class="mat-18">edit</mat-icon>
                    </button>
                </td>
            </tr>
            <tr>
                <td>Gestore</td>
                <td>
                    <mat-form-field class="field">
                        <mat-label>Gestore</mat-label>
                        <mat-select [(ngModel)]="currentItem.company" [compareWith]="dataservice.compareByCompanyId">
                            <mat-option *ngFor="let company of dataservice.companies" [value]="company">{{ company.name
                                }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </td>
            </tr>
            <tr>
                <td>Stato</td>
                <td>
                    <!-- <mat-slide-toggle [(ngModel)]="currentItem.archived"></mat-slide-toggle>-->
                    <mat-form-field class="field">
                        <mat-label>Stato</mat-label>
                        <mat-select [(ngModel)]="currentItem.status">

                            <mat-option [value]="0">INSTALLATO</mat-option>
                            <mat-option [value]="1">ARCHIVIATO</mat-option>
                            <mat-option [value]="2">COLLAUDO</mat-option>
                            <mat-option [value]="3">MAGAZZINO</mat-option>
                            <mat-option [value]="4">CONSEGNATO</mat-option>

                        </mat-select>

                    </mat-form-field>


                </td>
            </tr>
        </table>

        <button mat-raised-button [disabled]="!validate()" class="ideb" (click)="saveData()">SALVA</button>
    </div>
</div>