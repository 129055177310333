@if (progress) {
<app-progressspinner></app-progressspinner>
}
<h2 mat-dialog-title>Esporta letture <b>{{data.title}}</b></h2>
<mat-dialog-content>
    @if (content) {
    <table>
        <tr>
            @for (item of header; track $index) {
            <th>{{item}}</th>
            }
        </tr>
        @for (item of content; track $index) {
        <tr>
            @for (column of item.cols; track $index) {
            <td>{{column}}</td>
            }
        </tr>
        }
    </table>
    }
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-raised-button (click)="close()">Chiudi</button>
    <button mat-raised-button (click)="save()">Salva</button>
</mat-dialog-actions>