import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogTitle } from '@angular/material/dialog';
import { IBreadcrumbData, IBuildinCoverage, IBuilding, IBuildingApartmentCoverage, IConcentrator } from 'src/interfaces';
import { DataserviceService } from '../dataservice.service';
import { MatButtonModule } from '@angular/material/button';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule, NgIf, NgFor } from '@angular/common';
import { startWith, pairwise } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { MatTooltip } from '@angular/material/tooltip';
import { BreadcrumbnavComponent } from '../breadcrumbnav/breadcrumbnav.component';
import { ProgressspinnerComponent } from '../progressspinner/progressspinner.component';

@Component({
    selector: 'app-coverage',
    templateUrl: './coverage.component.html',
    styleUrl: './coverage.component.scss',
    standalone: true,
    imports: [NgIf, ProgressspinnerComponent, BreadcrumbnavComponent, MatFormField, MatLabel, MatSelect, FormsModule, ReactiveFormsModule, MatOption, NgFor, MatTooltip]
})

export class CoverageComponent implements OnInit {
  currentBuilding: IBuilding;
  concentrators: IConcentrator[] = [];
  currentConcentrator = 'All';
  coverage: IBuildinCoverage;
  control = new FormControl()
  progress = false;
  staircase = [];
  floors = [];
  breadcrumb: IBreadcrumbData[] = [];
  currentId = -1;

  constructor(public dataservice: DataserviceService, private route: ActivatedRoute, public dialogRef: MatDialogRef<CoverageComponent>, @Inject(MAT_DIALOG_DATA) public data: IBuilding) {
    this.currentBuilding = data;
    this.progress = true;
    this.route.paramMap.subscribe(async (map) => {
      this.currentId = parseInt(map.get('id'), 10);
      this.dataservice.getBuilding(this.currentId).subscribe(build => {
        this.currentBuilding = build as IBuilding;

        this.dataservice.getBuildingCoverage(this.currentId).subscribe(bdata => {
          this.progress = false;
          this.concentrators = bdata as IConcentrator[];
          this.breadcrumb.push({ label: 'HOME', url: '/' });
          this.breadcrumb.push({ label: 'stabili', url: '/buildings/' + this.currentBuilding.name });
          this.breadcrumb.push({ label: this.currentBuilding.name, url: '/apartments/' + this.currentBuilding.buildingId });
          this.breadcrumb.push({ label: 'CONTABILIZZATORI', url: 'javascript:;' });
        }, () => {
          this.progress = false;

          this.dataservice.toastr.error('Errore durante l\'operazione');

        });
      });
    });




  }

  ngOnInit() {
    this.control.valueChanges.pipe(
      startWith(this.control.value),
      pairwise()
    ).subscribe(
      ([old, value]) => {
        console.log(old, value);
        this.progress = true;
        this.dataservice.getBuildingConcentratorCoverage(this.currentId, value).subscribe(data => {
          this.progress = false;
          this.coverage = data as IBuildinCoverage;
          this.compileData();
        })
      }
    )
  }
  compileData() {
    for (const apa of this.coverage.apartments) {
      if (this.staircase.indexOf(apa.staircase) == -1) {
        this.staircase.push(apa.staircase);
      }
      if (this.floors.indexOf(apa.floor) === -1) {
        this.floors.push(apa.floor);
      }
    }
    this.staircase.sort();
    this.floors.sort();
  }

  getApa(staircase: string): IBuildingApartmentCoverage[] {
    const apa = this.coverage.apartments.filter(x => x.staircase === staircase);
    return apa.sort((a, b) => a.floor.localeCompare(b.floor));
  }

  getApas(staircase: string, floor: string): IBuildingApartmentCoverage[] {
    const apa = this.coverage.apartments.filter(x => x.staircase === staircase && x.floor == floor);
    return apa.sort((a, b) => a.floor.localeCompare(b.floor));
  }

  getApaClass(apa: IBuildingApartmentCoverage) {
    if (apa.devices.length === 0) {
      return '--nodev';
    }
    if (apa.deviceOk === apa.devices.length) {
      return '--ok';
    }
    if (apa.deviceOk > 0) {
      return '--warn';
    }

    return '--error';

  }

  getApaTooltip(apa: IBuildingApartmentCoverage) {
    let retval = apa.devices.length + ' validi\n';
    retval += apa.deviceOk + ' Letti\n';
    retval += apa.deviceInvalid + ' Invalidi\n';

    return retval;

  }
}


