import { BuildingComponent } from './../building/building.component';
import { IApartment, IBuilding } from './../../interfaces';
import { Component, Inject, OnInit } from '@angular/core';
import { DataserviceService } from '../dataservice.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow } from '@angular/material/table';

@Component({
    selector: 'app-buildinglist',
    templateUrl: './buildinglist.component.html',
    styleUrls: ['./buildinglist.component.scss'],
    standalone: true,
    imports: [MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow]
})
export class BuildinglistComponent implements OnInit {
    items: IBuilding[];
    displayedColumns: string[] = ['name', 'address', 'note', 'action'];

    constructor(public dataservice: DataserviceService, public dialogRef: MatDialogRef<BuildinglistComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }
    ngOnInit(): void {
        // console.log('Current user: ', this.dataservice.currentUser);
        if (this.dataservice.currentUser.role > 2) {
            this.dataservice.getBuildings().subscribe((data) => {
                this.items = data as IBuilding[];
            });
        } else {
            this.items = this.dataservice.currentUser.buildings;
        }
    }

    getAddress(item: IBuilding) {
        return item.address + ' ' + item.zip + ' ' + item.city + ' (' + item.province + ') ' + item.country;
    }

    select(item: IBuilding) {
        this.dialogRef.close(item);
    }

    cancel() {
        this.dialogRef.close();
    }
}
