<div class="tabcontainer">
    @if (currentBuildingBillInfo !== undefined && building !== undefined) {
    <div class="sectiontitleicon">
        <img src="/assets/billing.png" />
        <div>
            <h2 class="tabtitle">
                Bollettazione <span class="titlemark">{{ building?.name }}</span>
            </h2>
            <app-breadcrumbnav [data]="breadcrumb"></app-breadcrumbnav>
        </div>
    </div>
    }
    @if (currentBuildingBillInfo) {
    <div class="tabcontainer__form">
        <div class="tabcontainer__form__inner">
            <mat-form-field>
                <mat-label>Data Bollettazione</mat-label>
                <!-- #docregion toggle -->
                <input matInput [matDatepicker]="picker" [disabled]="!this.currentBilling.draft"
                    [(ngModel)]="currentBilling.timestamp" />
                <mat-hint>DD/MM/YYYY</mat-hint>
                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
                <!-- #enddocregion toggle -->
            </mat-form-field>
            <mat-form-field>
                <mat-label>Riferimento</mat-label>
                <mat-select [value]="this.currentBilling.reference" [disabled]="!this.currentBilling.draft">
                    @for (item of monts; track $index) {
                    <mat-option [value]="item" (click)="selMonth(item)">
                        {{ getBillStartDate(item) }}
                    </mat-option>
                    }
                </mat-select>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Elementi da cacolare</mat-label>
                <mat-select [(ngModel)]="currentBuildingBillInfo.billComponents">
                    <mat-option [value]="0">Solo letture</mat-option>
                    <mat-option [value]="1">Letture e costi</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Stato</mat-label>
                <mat-select [(ngModel)]="this.currentBilling.draft" (selectionChange)="changeStatus()">
                    <mat-option [value]="true">Aperta</mat-option>
                    <mat-option [value]="false">Chiusa</mat-option>
                </mat-select>
            </mat-form-field>
            <hr />
            @if (currentBuildingBillInfo.billComponents===1) {
            <div class="costBar">
                <mat-form-field class="field field__percs">
                    <mat-label>Costo riscaldamento</mat-label>
                    <input matInput placeholder="Costo riscaldamento" [disabled]="!this.currentBilling.draft"
                        [(ngModel)]="currentBuildingBillInfo.buildingHeatCost" />
                    <span matSuffix class="munit">€</span>
                </mat-form-field>
                <mat-form-field class="field field__percs">
                    <mat-label>Costo energia</mat-label>
                    <input matInput placeholder="Costo energia" [disabled]="!this.currentBilling.draft"
                        [(ngModel)]="currentBuildingBillInfo.buildingEnergyCost" />
                    <span matSuffix class="munit">€</span>
                </mat-form-field>
                <mat-form-field class="field field__percs">
                    <mat-label>Costo manutenzione</mat-label>
                    <input matInput placeholder="Costo manutenzione" [disabled]="!this.currentBilling.draft"
                        [(ngModel)]="currentBuildingBillInfo.buildingManutCost" />
                    <span matSuffix class="munit">€</span>
                </mat-form-field>

                <mat-form-field class="field field__percs">
                    <mat-label>% volontaria</mat-label>
                    <input matInput placeholder="% volontaria" [disabled]="!this.currentBilling.draft"
                        [(ngModel)]="currentBuildingBillInfo.voluntaryPerc" />
                    <span matSuffix class="munit">%</span>
                </mat-form-field>

                <mat-form-field class="field field__percs">
                    <mat-label>% involontaria</mat-label>
                    <input matInput placeholder="% involontaria" [disabled]="!this.currentBilling.draft"
                        [(ngModel)]="currentBuildingBillInfo.involuntaryPerc" />
                    <span matSuffix class="munit">%</span>
                </mat-form-field>
                <span>
                    @if (this.currentBilling.draft) {
                    <button mat-icon-button (click)="submit()"><mat-icon>restart_alt</mat-icon></button>
                    }
                    <button mat-icon-button (click)="getBuildingBilling()"><mat-icon>receipt_long</mat-icon></button>

                </span>
                <span>
                    <div>Costi volontari: {{ getCost(currentBuildingBillInfo.voluntaryCost) }}</div>
                    <div>Costi involontari: {{ getCost(currentBuildingBillInfo.involuntaryCost) }}</div>
                </span>
            </div>
            <hr />
            }

            <table mat-table [dataSource]="this.currentBuildingBillInfo.apartments" class="mat-elevation-z8">
                <ng-container matColumnDef="position">
                    <th mat-header-cell *matHeaderCellDef>Num.</th>
                    <td mat-cell *matCellDef="let element; let i = index">{{ i + 1 }}</td>
                </ng-container>
                <ng-container matColumnDef="pname">
                    <th mat-header-cell *matHeaderCellDef>Proprietario.</th>
                    <td mat-cell *matCellDef="let element">{{ element.name }}</td>
                </ng-container>
                <ng-container matColumnDef="apatotal">
                    <th mat-header-cell *matHeaderCellDef>Letture</th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.meterTotalValue }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="billable">
                    <th mat-header-cell *matHeaderCellDef>
                        <button mat-button aria-hidden="false" (click)="getAllBill()">
                            <mat-icon class="coloring__green selectable">receipt_long</mat-icon>
                            Bollette

                        </button>
                    </th>
                    <td mat-cell *matCellDef="let element">
                        @if (getBillable(element)) {
                        <mat-icon class="coloring__green selectable" (click)="getBill(element)">receipt_long</mat-icon>
                        }
                        @else {
                        <mat-icon class="coloring__red">error_outline</mat-icon>
                        }
                    </td>
                </ng-container>
                <ng-container matColumnDef="devices">
                    <th mat-header-cell *matHeaderCellDef>Contabilizzatori</th>
                    <td mat-cell *matCellDef="let element">
                        @for (dev of element.meterDevices; track dev; ) {

                        <div>
                            <span class="devInfo" (click)="selectDevice(dev)">
                                {{ dev.serial }} &nbsp;{{ dev.name }} &nbsp;{{dataservice.getMediumName(dev.medium)}}
                            </span>
                            @for (stat of dev.stats; track stat; let i=$index) {
                            <span class="meterStats" (click)="selStat(element, dev, i)"
                                [ngClass]="{ 'meterStats--selected': i === getIndex(element, dev)}"
                                class="meterStats--warn{{stat.dataCalcMode}}">
                                <div>{{ getStatDate(stat) }}</div>
                                @if (stat.dataCalcMode===0) {
                                @if (dev.medium===8) {
                                <div>{{ getValueStr(dev, stat.readoutValue) }}&nbsp;[{{ getValueStr(dev, stat.value) }}]
                                </div>
                                } @else {
                                <div>{{ getValueStr(dev, stat.value) }}</div>
                                }
                                }
                                @if (stat.dataCalcMode===1) {
                                @if (dev.medium===8) {
                                <div>{{ getValueStr(dev, stat.fakeData) }}&nbsp;[{{ getValueStr(dev, stat.value) }}]
                                </div> } @else {
                                <div>{{ getValueStr(dev, stat.value) }}</div>
                                }
                                <mat-icon aria-hidden="false" class="meterStats__fixicon">auto_fix_high</mat-icon>
                                }
                                @if (stat.dataCalcMode===2) {
                                <div>{{ getValueStr(dev, stat.value) }}</div>
                                <mat-icon aria-hidden="false" class="meterStats__fixicon">auto_fix_high</mat-icon>
                                }
                            </span>
                            }

                            <!--:{{ getIndex(element, dev) }}-->
                        </div>
                        }
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="getColumns()"></tr>
                <tr mat-row *matRowDef="let row; columns: getColumns()" (click)="select(row)"></tr>

                <!--<tr mat-footer-row *matFooterRowDef="getColumns()"></tr>-->
            </table>
            <mat-paginator [pageSizeOptions]="[10, 20, 100]" showFirstLastButtons aria-label="Seleziona pagina">
            </mat-paginator>
        </div>
    </div>
    }
</div>